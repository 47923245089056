import React from "react";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  Button,
  Dialog,
  Tooltip,
  Grid,
  IconButton,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Cancel, Summarize } from "@mui/icons-material";

import { Chip } from "../../../components";
import convertDate from "../../../utils/convertDate";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

const FontSizeTypography = styled(Typography)`
  font-size: "18px" !important;
  font-weight: "700" !important;
`;

export default function RequestDetails({
  requestDetails,
  requestDetailsDailog,
  setRequestDetailsDailog,
}) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={requestDetailsDailog}
      onClose={() => setRequestDetailsDailog(false)}
      fullWidth
    >
      <Grid container spacing={0} style={{ borderBottom: "1px solid #cbcbcb" }}>
        <Grid style={{ padding: "16px 24px" }} item xs={8}>
          <Typography variant="h4">Request Logs Details</Typography>
        </Grid>

        <Grid item xs={4}>
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            style={{ float: "right", padding: "16px 24px" }}
            onClick={() => setRequestDetailsDailog(false)}
          >
            <Cancel />
          </IconButton>
        </Grid>
      </Grid>

      <DialogContent style={{ margin: "10px 0" }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} />

          <Grid item xs={2}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Created At
            </FontSizeTypography>
          </Grid>
          <Grid item xs={4} textAlign={"start"}>
            <Typography>
              {convertDate(requestDetails[0]?.created_at)}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Finished At
            </FontSizeTypography>
          </Grid>

          <Grid item xs={4} textAlign={"start"}>
            <Typography>
              {convertDate(requestDetails[0]?.finished_at)}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Process Type
            </FontSizeTypography>
          </Grid>
          <Grid item xs={4}>
            <Typography textAlign={"start"}>
              {requestDetails[0]?.process_type.toUpperCase()}
            </Typography>
          </Grid>

          <Grid item xs={2}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Leads Count
            </FontSizeTypography>
          </Grid>
          <Grid item xs={4} textAlign={"start"}>
            <Typography>{requestDetails[0]?.leads_count}</Typography>
          </Grid>

          <Grid item xs={2}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              File URL
            </FontSizeTypography>
          </Grid>
          <Grid item xs={4} textAlign={"start"}>
            {!!requestDetails[0]?.file_url ? (
              <Button
                variant="contained"
                color="primary"
                href={requestDetails[0]?.file_url}
              >
                See Bulk Upload Report
              </Button>
            ) : (
              "Not Available"
            )}
          </Grid>

          <Grid item xs={2}>
            <FontSizeTypography style={{ fontWeight: "700" }}>
              Status
            </FontSizeTypography>
          </Grid>

          <Grid item xs={4} justifyContent={"flex-start"}>
            {requestDetails[0]?.status == "finished" ? (
              <Chip type="success" label="Generated" />
            ) : (
              <Chip type="error" label="Not Generated" />
            )}
          </Grid>

          <Grid item xs={6} />
        </Grid>
      </DialogContent>

      <DialogActions
        style={{ padding: "16px 24px", borderTop: "1px solid #cbcbcb" }}
      >
        {requestDetails[0]?.status == "finished" && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Summarize />}
              href={requestDetails[0]?.report_url}
            >
              Download Report
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}
