import { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import RCTable from "./rc details/RCTable";
import { Paper } from "../../../components";
import pages from "../../../constants/pages";
import ActionTable from "./action/ActionTable";
import Loader from "./../../../components/Loader";
import useComponent from "./../../../hooks/useComponent";
import RCdetailDailog from "./rc details/RCDetailsDailog";
import PageHeader from "./../../../components/PageHeader";
import { useAxios, useCustomAuthAxios } from "../../../hooks";
import CustomerDetails from "./customer details/CustomerDetails";
import TransactionTable from "./transaction table/TransactionTable";
import NotificationsTable from "./customer details/NotificationsTable";

export default function LeadsDetails() {
  const axios = useAxios();

  const rcAxios = useAxios({
    baseURL: "rcApi",
  });

  const rcAxios_2 = useCustomAuthAxios({
    baseURL: "rcApi",
  });

  const { loader } = useComponent();

  const { customerID } = useParams();

  const location = useLocation();

  const navigate = useNavigate();

  const [action, setAction] = useState([]);

  const [rcDetail, setRCDetail] = useState([]);

  const [rcDetails, setRCDetails] = useState([]);

  const [transactions, setTransactions] = useState([]);

  const [rcDetailsDailog, setRCDetailsDailog] = useState(false);

  const [notificationsTable, setNotificationsTable] = useState([]);

  const [viewRCButtonLoading, setViewRCButtonLoading] = useState([]);

  const [customerDetails, setCustomerDetails] = useState();

  const [formValue, setFormValue] = useState({
    name: "",
    mobile: "",
  });

  const [editState, setEditState] = useState(true);

  useEffect(() => {
    loader.start();

    axios({ url: "/customer?customer_id=" + customerID }).then((response) => {
      if (response.status) {
        setCustomerDetails(response.data);

        setFormValue({
          name: response.data[0].name,
          mobile: response.data[0].mobile,
        });

        let RCNumber = response.data.map((item) => {
          return item.rc_number;
        });

        axios({
          url: "/customer/rc-details",
          method: "POST",
          data: { rc_list: RCNumber },
          disableRedirect: true,
        }).then((response) => {
          if (response.status) {
            setRCDetails(response.data);

            setViewRCButtonLoading(
              response.data.map((items) => {
                return false;
              })
            );
          }
        });

        axios({
          url: "/customer/actions/" + response.data[0].mobile,
          disableRedirect: true,
        }).then((response) => {
          if (response.status) {
            setAction(response.data);
          }
        });

        axios({
          url: "/customer/transactions/" + response.data[0].customer_id,
          disableError: true,
        }).then((response) => {
          if (response.status) {
            setTransactions(response.data);
          }
        });

        axios({
          url: "/customer/notifications/" + response.data[0].customer_id,
          disableError: true,
        }).then((response) => {
          if (response.status) {
            setNotificationsTable(response.data);
          }
        });

        loader.stop();
      }
    });
  }, [customerID, axios]);

  function handleRCDetails(RCNumber, RCDetail) {
    setViewRCButtonLoading((prev) => {
      return prev.map((itm, indx) => {
        return indx === RCDetail.rowIndex - 1 ? true : false;
      });
    });

    if (RCDetail.rowData[0] === undefined) {
      rcAxios({
        url: "/vahan-login",
        method: "POST",
        data: { email: "admin@verismart.ai", password: "Dolphinchat@123" },
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          rcAxios_2({
            url: "/vahan-get-details/" + RCNumber,
            authToken: response.data.auth_token,
            disableRedirect: true,
          }).then((resp) => {
            if (resp.status) {
              setRCDetails((prev) => {
                let prevData = prev.map((item) => {
                  if (item.rc_regn_no === RCNumber) {
                    item = resp.data;
                  }
                  return item;
                });

                return prevData;
              });

              setRCDetail([resp.data]);

              setRCDetailsDailog(true);

              setViewRCButtonLoading((prev) => {
                return prev.map((itm, indx) => {
                  return indx === RCDetail.rowIndex - 1 && false;
                });
              });
            } else {
              setRCDetails((prev) => {
                let prevData = prev.map((item) => {
                  if (item.vehicle_number === RCNumber) {
                    item.details = resp.data;
                  }

                  return item;
                });

                return prevData;
              });
            }
          });
        }
      });
    } else {
      setRCDetail([
        ...rcDetails.filter((items) => {
          return items.rc_regn_no == RCNumber;
        }),
      ]);

      setViewRCButtonLoading((prev) => {
        return prev.map((itm, indx) => {
          return indx === RCDetail.rowIndex - 1 && false;
        });
      });

      setRCDetailsDailog(true);
    }
  }

  return (
    <Loader>
      <PageHeader
        pageName="Leads Details"
        buttons={[
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(pages.LeadsLists.route + location.search)}
          >
            Back
          </Button>,
        ]}
      />

      <CustomerDetails
        customerDetails={customerDetails}
        formValue={formValue}
        setFormValue={setFormValue}
        editState={editState}
        setEditState={setEditState}
      />

      <Paper>
        <Grid item container m={5}>
          <Typography variant="h3">Actions</Typography>
        </Grid>

        <Grid item xs={12}>
          <ActionTable action={action} />
        </Grid>
      </Paper>

      <Paper>
        <Grid item xs={12}>
          <RCTable
            rcDetails={rcDetails}
            customerDetails={customerDetails}
            handleRCDetails={handleRCDetails}
            viewRCButtonLoading={viewRCButtonLoading}
          />
        </Grid>
      </Paper>

      <Paper>
        <Grid item m={5}>
          <Typography variant="h3">Transaction</Typography>
        </Grid>

        <Grid item xs={12}>
          <TransactionTable transactions={transactions} />
        </Grid>
      </Paper>

      <Paper>
        <Grid item m={5}>
          <Typography variant="h3">Notifications</Typography>
        </Grid>

        <Grid item xs={12}>
          <NotificationsTable notificationsTable={notificationsTable} />
        </Grid>

        <RCdetailDailog
          rcDetail={rcDetail}
          rcDetailsDailog={rcDetailsDailog}
          setRCDetailsDailog={setRCDetailsDailog}
        />
      </Paper>
    </Loader>
  );
}
