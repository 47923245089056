import { TextField as MuiTextField } from "@mui/material";
import styled from "styled-components/macro";
import { get } from "lodash";

const TextFieldWithCSS = styled(MuiTextField)`
  .MuiOutlinedInput-notchedOutline {
    border-color: #4076d2;
  }

  .MuiOutlinedInput-root {
    font-size: 16px;
  }

  .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
    background-color: #fff;
    font-size: 16px;
  }

  .MuiInputLabel-asterisk {
    color: ${(props) => (Boolean(props.required && !props.value) ? "red" : "")};
  }
`;

export default function TextField({ formikProps, ...props }) {
  if (!!formikProps) {
    return (
      <TextFieldWithCSS
        onChange={formikProps.handleChange}
        error={Boolean(
          get(formikProps.touched, props.name) &&
            get(formikProps.errors, props.name)
        )}
        helperText={
          get(formikProps.touched, props.name) &&
          get(formikProps.errors, props.name)
        }
        onBlur={formikProps.handleBlur}
        value={get(formikProps.values, props.name, "")}
        fullWidth
        variant="outlined"
        {...props}
      />
    );
  }

  return <TextFieldWithCSS variant="outlined" fullWidth {...props} />;
}
