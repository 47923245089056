import CancelIcon from "@mui/icons-material/Cancel";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { Button, Grid, MenuItem, Typography } from "@mui/material";

import { uploadToS3 } from "../../utils";
import Autocomplete from "./Autocomplete";
import { ComponentHeader, Paper, TextField } from "../../components";

export default function FormDisplay({ formikProps, editState, setEditState }) {
  function handleChange(e, name, formikProps) {
    let fieldName =
      name !== "policyBazaarPercent"
        ? "policyBazaarPercent"
        : "turtleMintPercent";

    formikProps.setValues((prevValue) => ({
      ...prevValue,
      [name]: e.target.value,
      [fieldName]: 100 - e.target.value,
    }));
  }

  function handelViewImg() {
    console.log("handelViewImg");
  }

  function handelRemoveImg(index) {
    formikProps.setValues((prev) => ({
      ...prev,
      ["bannerImages"]: prev["bannerImages"].filter(
        (items, indx) => indx !== index
      ),
    }));
  }

  function handleUploadFile(e) {
    const file = e.target.files[0];

    const uploadBucket = {
      name: "dolphinchat-chat",
      region: "ap-south-1",
      identityPoolID: "ap-south-1:16fa1fcf-7cdd-4593-9ee0-6f621526defc",
    };

    uploadToS3({
      bucket: uploadBucket,
      filePath: "insurance_settings/" + new Date().getTime() + "_" + file.name,
      file,
    }).then((response) => {
      if (response.status) {
        formikProps.setValues((prevValue) => ({
          ...prevValue,
          bannerImages: [...prevValue.bannerImages, response.data.Location],
        }));
      } else {
      }
    });
  }

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Broadcast Settings"}
          buttons={[
            <Button
              variant="contained"
              onClick={() => {
                setEditState(!editState);
              }}
            >
              Edit Settings
            </Button>,
          ]}
        />
      </Grid>
      <Grid item container display={"flex"}>
        <Grid item xs={6} p={2}>
          <Grid item>
            <Typography mb={2}>Daily Notification Limit</Typography>

            <TextField
              required
              name="dailyNotificationLimit"
              disabled={!editState}
              formikProps={formikProps}
            ></TextField>
          </Grid>

          <Grid item>
            <Typography mt={5} mb={2}>
              Policy Bazaar Percent
            </Typography>

            <TextField
              required
              name="policyBazaarPercent"
              disabled={!editState}
              formikProps={formikProps}
              onChange={(e) =>
                handleChange(e, "policyBazaarPercent", formikProps)
              }
            ></TextField>
          </Grid>

          <Grid item>
            <Typography mt={5} mb={2}>
              Turtle Mint Percent
            </Typography>

            <TextField
              required
              name="turtleMintPercent"
              disabled={!editState}
              formikProps={formikProps}
              onChange={(e) =>
                handleChange(e, "turtleMintPercent", formikProps)
              }
            ></TextField>
          </Grid>

          <Grid>
            <Grid
              mt={5}
              mb={2}
              style={{
                border: "2px solid green",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "10px",
                borderRadius: "5px",
                pointerEvents: !editState && "none",
                background: !editState && " #a097972b",
              }}
            >
              {formikProps.values?.bannerImages?.map((items, index) => {
                return (
                  <div>
                    <img
                      src={items}
                      style={{
                        width: "60px",
                        height: "60px",
                        padding: "3px",
                        borderRadius: "10px",
                        margin: "5px",
                        boxShadow: "0px 0px 1px 1px #0080006e",
                      }}
                      onClick={handelViewImg}
                    ></img>
                    {editState && (
                      <CancelIcon
                        sx={{
                          fontSize: "1.7rem",
                          color: "red",
                          position: "relative",
                          right: "20px",
                          background: "white",
                          borderRadius: "30px",
                          verticalAlign: "top",
                          ":hover": {
                            padding: "2px",
                          },
                        }}
                        onClick={() => handelRemoveImg(index)}
                      ></CancelIcon>
                    )}
                  </div>
                );
              })}
              <div
                style={{
                  border: "1px solid black",
                  width: "50px",
                  height: "50px",
                  margin: "10px 5px",
                  borderRadius: "5px",
                  boxShadow: "0px 0px 1px 1px #0080006e",
                }}
              >
                <label for="file">
                  <ControlPointIcon
                    for="file"
                    sx={{ fontSize: "3rem", color: "green" }}
                  ></ControlPointIcon>
                </label>
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="file"
                  name="file"
                  onChange={(e) => {
                    handleUploadFile(e);
                  }}
                  accept=".jpg .png"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} p={2}>
          <Grid>
            <Typography mb={2}>Queue Days Before</Typography>

            <TextField
              required
              name="buildQueueDaysBefore"
              disabled={!editState}
              formikProps={formikProps}
              onChange={(e) => {
                formikProps.values.notificationDays[0] = e.target.value;
                formikProps.setValues((prevValue) => ({
                  ...prevValue,
                  ["buildQueueDaysBefore"]: e.target.value,
                }));
              }}
            ></TextField>
          </Grid>

          <Grid>
            <Typography mt={5} mb={2}>
              Notification Days
            </Typography>

            <Autocomplete
              name="notificationDays"
              defaults={{
                primaryKey: "",
                displayLabel: "",
              }}
              multiple={true}
              required={true}
              options={formikProps.values.notificationDays}
              disableField={!editState}
              formikProps={formikProps}
              sx={{ mt: "10px" }}
            />
          </Grid>

          <Grid>
            <Typography mt={5} mb={2}>
              Testing
            </Typography>

            <TextField
              select
              name="isTesting"
              disabled={!editState}
              formikProps={formikProps}
              onChange={(e) => {
                formikProps.setValues((prevValue) => ({
                  ...prevValue,
                  isTesting: e.target.value,
                }));
              }}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </TextField>
          </Grid>
          {formikProps.values.isTesting && (
            <Grid item>
              <Typography mt={5} mb={2}>
                Testing Mobile
              </Typography>

              <TextField
                required
                name="testingMobile"
                disabled={!editState}
                formikProps={formikProps}
              ></TextField>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "black",
          position: "absolute",
          top: "0",
          left: "0",
          opacity: 0.3,
        }}
      ></div> */}

      {editState && (
        <Grid item container justifyContent={"end"}>
          <Button
            variant="contained"
            disabled={!formikProps.isValid}
            onClick={() => {
              setEditState(!editState);
              formikProps.handleSubmit();
            }}
          >
            Submit
          </Button>

          <Button
            variant="outlined"
            sx={{ ml: "10px" }}
            onClick={() => {
              setEditState(!editState);
            }}
          >
            Cancel
          </Button>
        </Grid>
      )}
      {/* <ImgDialog /> */}
    </Paper>
  );
}
