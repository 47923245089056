import { Formik } from "formik";
import { BsEye } from "react-icons/bs";
import { LoadingButton } from "@mui/lab";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import React, { Fragment, useState } from "react";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";

import DeleteRCDetailsDailog from "./DeleteRCDialog";
import { useAlert, useAxios } from "../../../../hooks";
import { DataTable, TextField } from "../../../../components";

export default function RCTable({
  rcDetails,
  customerDetails,
  handleRCDetails,
  viewRCButtonLoading,
}) {
  const axios = useAxios();

  const { alert } = useAlert();

  const dispatch = useDispatch();

  const [deleteRCNumber, setDeleteRCNumber] = useState();

  const [rcDeleteDetailsDailog, setRCDeleteDetailsDailog] = useState(false);

  var formatted_data = [];

  const [formValue, setFormValue] = useState({ addVehicle: "" });

  if (!!rcDetails) {
    for (const key in rcDetails.details) {
      if (typeof rcDetails[key] === "string") {
        formatted_data.push({
          key: key.replace(/_/g, " "),
          value: rcDetails[key],
        });
      } else {
        formatted_data.push({
          key: key.replace(/_/g, " "),
          value: rcDetails.details[key],
        });
      }
    }
  }

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
      rowsPerPage: 50,
      rowsPerPageOptions: [100],
      filter: false,
      viewColumns: false,
    },
    print: false,
  };

  const columns = [
    {
      name: "rc_owner_name",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "rc_regn_no",
      label: "Vehicle Number",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "rc_maker_desc",
      label: "Make",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "rc_maker_model",
      label: "Model",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "rc_fit_upto",
      label: "RC Expiry",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "rc_insurance_comp",
      label: "Prev Insurance",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "rc_insurance_upto",
      label: "Insurance Expiry",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "rc_eng_no",
      label: "Engine Number",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "rc_chasi_no",
      label: "Chassis Number",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "rc_regn_no",
      label: "Action",
      options: {
        customBodyRender: (value, val) => {
          return (
            <Fragment>
              <Grid display={"flex"}>
                <Tooltip title="View RC Details">
                  <IconButton
                    onClick={() => handleRCDetails(value, val)}
                    loading={viewRCButtonLoading[val.rowIndex - 1]}
                    sx={{ color: "green" }}
                  >
                    <BsEye />
                  </IconButton>
                </Tooltip>

                {val.rowData[0] && (
                  <Tooltip title="Delete RC Details">
                    <IconButton
                      onClick={() => {
                        setDeleteRCNumber(value);
                        setRCDeleteDetailsDailog(true);
                      }}
                      sx={{ color: "red" }}
                    >
                      <MdDelete />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Fragment>
          );
        },
      },
    },
  ];

  return (
    <React.Fragment>
      <Grid container item mb={3} justifyContent={"space-between"}>
        <Typography variant="h3">RC Details</Typography>

        <Grid item display={"flex"} justifyContent={"end"} alignSelf={"end"}>
          <Formik
            initialValues={formValue}
            onSubmit={(formData, { setSubmitting }) => {
              axios({
                url: "/customer/" + customerDetails[0].customer_id,
                method: "PUT",
                data: {
                  vehicle: {
                    add: [
                      {
                        rc_number: formData.addVehicle,
                      },
                    ],
                  },
                },
                disableRedirect: true,
              }).then((response) => {
                if (response.status) {
                  dispatch(
                    alert({
                      type: "success",
                      message: response.message.displayMessage,
                    })
                  );
                  setSubmitting(false);
                } else {
                  dispatch(
                    alert({
                      type: "error",
                      message: Object.values(response.data.vehicles),
                    })
                  );

                  setSubmitting(false);
                }
              });
            }}
          >
            {(formikProps) => {
              return (
                <Fragment>
                  <TextField
                    name={"addVehicle"}
                    label={"Add Vehicle"}
                    formikProps={formikProps}
                  />
                  <LoadingButton
                    variant="outlined"
                    sx={{ ml: 3 }}
                    onClick={formikProps.handleSubmit}
                    loading={formikProps.isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Fragment>
              );
            }}
          </Formik>
        </Grid>
      </Grid>

      <DataTable columns={columns} options={options} data={rcDetails} />

      <DeleteRCDetailsDailog
        deleteRCNumber={deleteRCNumber}
        customerDetails={customerDetails}
        rcDeleteDetailsDailog={rcDeleteDetailsDailog}
        setRCDeleteDetailsDailog={setRCDeleteDetailsDailog}
      />
    </React.Fragment>
  );
}
