import { Chip } from "@mui/material";
import MuiAutoComplete from "@mui/material/Autocomplete";
import { get } from "lodash";
import { Fragment } from "react";
import TextField from "./../../components/TextField";

export default function Autocomplete({
  label,
  name,
  defaults,
  options,
  formikProps,
  multiple = false,
  required,
  disableField = false,
  ...props
}) {
  let initialValue = formikProps.values[name];

  const updatedOptions = [...options];

  if (!multiple) {
    initialValue = options.find(
      (option) =>
        option[defaults.primaryKey] === get(formikProps.values, name, "")
    );

    if (!initialValue) {
      initialValue = {
        [defaults.primaryKey]: "",
      };
    }
  }

  return (
    <Fragment>
      <MuiAutoComplete
        freeSolo
        fullWidth
        multiple={multiple ? true : false}
        value={initialValue}
        forcePopupIcon={true}
        options={updatedOptions}
        getOptionLabel={(option) => get(option, defaults.displayLabel, "")}
        onChange={(_, values) => {
          values = values
            .map((items) => {
              if (Number(items)) {
                return Number(items);
              }
            })
            .filter((items) => {
              return items !== undefined;
            });

          values = [...new Set(values)];

          formikProps.setFieldValue("buildQueueDaysBefore", values[0]);

          multiple
            ? formikProps.setFieldValue(name, values)
            : formikProps.setValues((prevValue) => ({
                ...prevValue,
                [name]: values[defaults.primaryKey],
              }));
        }}
        {...props}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            return (
              <Chip
                variant="outlined"
                label={option}
                sx={{
                  backgroundColor: option.color,
                  color: option.color && "white",
                  border: !option.color && "1px solid #007aff",
                }}
                {...getTagProps({ index })}
              />
            );
          })
        }
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              required={required}
              label={label}
              name={name}
              error={Boolean(
                get(formikProps, "touched." + name) &&
                  get(formikProps, "errors." + name)
              )}
              helperText={
                get(formikProps, "touched." + name) &&
                get(formikProps, "errors." + name)
              }
              onBlur={get(formikProps, "handleBlur")}
            />
          );
        }}
        disabled={disableField}
      />
    </Fragment>
  );
}
