import React, { useState } from "react";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { Cancel } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers";

import { useAxios } from "../../../hooks";
import pages from "../../../constants/pages";
import Label from "../../../components/Label";
import FilterDataForm from "./FilterDataForm";
import TextField from "../../../components/TextField";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

export default function FilterDataDialog({
  setFilter,
  filter,
  setLeads,
  filterColumns,
  filterData,
  setFilterData,
}) {
  const classes = useStyles();

  const axios = useAxios();

  const navigate = useNavigate();

  const handleClose = () => {
    setFilter(false);
  };

  function handleClearFilter() {
    navigate(pages.transactionList.route);

    setFilterData({
      filters: [
        {
          key: "",
          value: "",
          operator: "",
          relation: "",
        },
      ],
      time: {
        startTime: "",
        endTime: "",
      },
    });

    axios({
      url: `/leads`,
    }).then((response) => {
      if (response.status) {
        setLeads(response.data.lead_list);

        handleClose();
      }
    });
  }

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={filter}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Filter Data</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <Formik
          initialValues={filterData}
          enableReinitialize
          onSubmit={(formData, { setSubmitting }) => {
            let stage = formData.filters.filter((item) => {
              return item.key === "stage";
            });
            let moblie = formData.filters.filter((item) => {
              return item.key === "mobile";
            });

            let vehicleNumber = formData.filters.filter((item) => {
              return item.key === "vehicle_number";
            });

            let expiresIn = formData.filters.filter((item) => {
              return item.key === "expires_in";
            });

            axios({
              url:
                "/leads?skip=0&limit=1200" +
                (moblie.length ? "&mobile=" + moblie[0]?.value : "") +
                (stage.length ? "&stage=" + stage[0]?.value : "") +
                (expiresIn.length ? "&expires_in=" + expiresIn[0]?.value : "") +
                (vehicleNumber.length
                  ? "&vehicle_number=" + vehicleNumber[0]?.value
                  : "") +
                (formData.startTime > 0
                  ? "&start_date=" +
                    new Date(formData.startTime).getTime() / 1000 +
                    (formData.endTime
                      ? "&end_date=" +
                        new Date(formData.endTime).getTime() / 1000
                      : "")
                  : ""),
              method: "GET",
            }).then((response) => {
              if (response.status) {
                setSubmitting(false);
                setLeads(response.data.lead_list);
                setFilterData(formData);
                handleClose();
              } else {
                setSubmitting(false);
              }
            });
          }}
        >
          {(dialogFormikProps) => {
            return (
              <React.Fragment>
                <DialogContent
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="caption1"
                        style={{ color: "rgb(128, 128, 128)" }}
                      >
                        Apply filter to the table data
                      </Typography>
                    </Grid>

                    <Grid item container display={"flex"} spacing={3}>
                      <Grid item xs={6}>
                        <Label
                          title={"Start Date"}
                          formikProps={dialogFormikProps}
                          name={"startTime"}
                        />

                        <DateTimePicker
                          InputProps={{ readOnly: true }}
                          name="startTime"
                          formikProps={dialogFormikProps}
                          inputProps={{
                            "aria-label": "Without label",
                            readOnly: true,
                          }}
                          value={dialogFormikProps.values.startTime}
                          onChange={(newValue) => {
                            dialogFormikProps.setFieldValue(
                              "startTime",
                              newValue
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              readOnly={true}
                              InputProps={{ readOnly: true }}
                              name="startTime"
                              formikProps={dialogFormikProps}
                              {...params}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Label
                          title={"End Date"}
                          formikProps={dialogFormikProps}
                          name={"endTime"}
                        />

                        <DateTimePicker
                          InputProps={{ readOnly: true }}
                          name="endTime"
                          formikProps={dialogFormikProps}
                          minDate={dialogFormikProps.values.startTime}
                          inputProps={{
                            "aria-label": "Without label",
                            readOnly: true,
                          }}
                          value={dialogFormikProps.values.endTime}
                          onChange={(newValue) => {
                            dialogFormikProps.setFieldValue(
                              "endTime",
                              newValue
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              readOnly={true}
                              InputProps={{ readOnly: true }}
                              name="endTime"
                              formikProps={dialogFormikProps}
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={3}>
                      <FilterDataForm
                        dialogFormikProps={dialogFormikProps}
                        filterColumns={filterColumns}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>

                <DialogActions style={{ padding: "12px 25px 24px" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={9}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      display={"flex"}
                      justifyContent={"space-between"}
                      style={{ textAlign: "end" }}
                    >
                      <Button
                        onClick={handleClearFilter}
                        variant="contained"
                        color="primary"
                        disabled={dialogFormikProps.dirty}
                      >
                        Clear Filter
                      </Button>

                      <Button
                        onClick={dialogFormikProps.handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={!dialogFormikProps.dirty}
                      >
                        Apply Filter
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              </React.Fragment>
            );
          }}
        </Formik>
      </MUIDialog>
    </React.Fragment>
  );
}
