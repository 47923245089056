import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Dialog as MUIDialog,
  Grid,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { Paper } from "../../../../components";
import MessageSentForm from "./MessageSentForm";
import { templateValue } from "./templateValue";
import { useComponent, useAxios } from "../../../../hooks";
import WhatsappPreview from "./whatsapp interface/WhatsappPreview";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "95%" },
  iconButton: {
    float: "right",
    padding: "16px 24px",
  },
  dialogContent: {
    padding: "5px 8px 5px",
    background: "#cbcbcb38",
  },
}));

export default function MessageSent({
  popUp,
  setPopUp,
  formikProps,
  templates,
  numberList = [],
}) {
  const classes = useStyles();

  const axios = useAxios();

  const { alert } = useComponent();

  templateValue.userID = formikProps?.ID;
  templateValue.phoneNumber = formikProps?.values?.mobileNumber;
  templateValue.numberList = numberList;
  templateValue.multiple = numberList > 0 ? true : false;

  const handleClose = () => {
    setPopUp(false);
  };

  return (
    <MUIDialog
      classes={{ paper: classes.paper }}
      open={popUp}
      onClose={handleClose}
    >
      <Grid
        container
        spacing={0}
        style={{
          borderBottom: "1px solid #cbcbcb",
        }}
      >
        <Grid style={{ padding: "16px 24px" }} item xs={8}>
          <Typography variant="h5">Send Message</Typography>
        </Grid>

        <Grid item xs={4}>
          <IconButton
            disableRipple
            className={classes.iconButton}
            onClick={handleClose}
          >
            <Cancel />
          </IconButton>
        </Grid>
      </Grid>

      <Formik
        initialValues={templateValue}
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={(formData, { setSubmitting }) => {
          let transactionIds = [];

          if (!formikProps) {
            transactionIds = numberList;
          } else {
            transactionIds = [formikProps.values.transactionId];
          }

          var data = {
            template_id: formData.templateID,
            transaction_ids: transactionIds,
            parameters: [],
          };

          if (formData.templateBody.count_of_variable > 0) {
            for (let body in formData.templateBody.example) {
              if (body !== "body_text") {
                data.parameters.push(formData.templateBody.example[body]);
              }
            }
          }

          if (formData.templateButtons.count_of_variable > 0) {
            data.parameters.push(...formData.templateButtons.example);
          }

          axios({
            url: "/actionable/",
            method: "POST",
            data: data,
            disableRedirect: true,
          }).then((response) => {
            if (response.status) {
              setSubmitting(false);
              alert.success(response?.message?.displayMessage);
              handleClose();
            } else {
              setSubmitting(false);
            }
          });
        }}
      >
        {(dialogFormikProps) => (
          <React.Fragment>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={8} display="flex">
                  <MessageSentForm
                    formikProps={dialogFormikProps}
                    templates={templates}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Paper style={{ marginBottom: "0" }}>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ color: "#808080b3" }}
                      >
                        The message for user will look like this.
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <WhatsappPreview formikProps={dialogFormikProps.values} />
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions style={{ padding: "12px 24px 24px" }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item xs></Grid>

                <Grid item xs style={{ textAlign: "end" }}>
                  <Button
                    onClick={dialogFormikProps.handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={
                      dialogFormikProps.dirty
                        ? dialogFormikProps.isSubmitting ||
                          !dialogFormikProps.isValid
                        : !dialogFormikProps.dirty
                    }
                  >
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </React.Fragment>
        )}
      </Formik>
    </MUIDialog>
  );
}

const validationSchema = Yup.object().shape({
  templateID: Yup.string().required("This field is required."),

  phoneNumber: Yup.string().when("multiple", {
    is: (val) => val === true,
    then: Yup.string().required("This field is required"),
  }),
});
