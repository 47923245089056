import React from "react";
import styled from "styled-components/macro";
import { DialogContent, Dialog, Grid, Button, Typography } from "@mui/material";

import { LoadingButton } from "@mui/lab";

const Card = styled(Dialog)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default function ConfirmDialog({
  confirm,
  loading,
  setConfirm,
  formikProps,
}) {
  const handleConfirmDialogClose = () => {
    setConfirm(false);
  };

  return (
    <Card
      open={confirm}
      onClose={handleConfirmDialogClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "800px!important",
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={3} style={{ padding: "20px" }}>
          <Grid item xs={12} display="flex">
            <Typography>Are you sure want to Update Stage ?</Typography>
          </Grid>

          <Grid item xs />

          <Grid item>
            <Button variant="outlined" onClick={handleConfirmDialogClose}>
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={formikProps.handleSubmit}
              disabled={!formikProps.dirty || !formikProps.isValid}
            >
              Confirm
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Card>
  );
}
