// SentNotificationsReport.js

import React from "react";
import Typography from "@mui/material/Typography";

const SentNotificationsReport = ({ heading }) => {
  return (
    <Typography
      variant="h2"
      gutterBottom
      sx={{
        color: "#474747",
        fontWeight: 600,
        textAlign: "center",
        marginBottom: 2,
      }}
    >
      {heading}
    </Typography>
  );
};

export default SentNotificationsReport;
