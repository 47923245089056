import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  leadsObject,
  objectFromResponseData,
} from "./details components/leadsObject";
import pages from "../../constants/pages";
import Form from "./details components/Form";
import { useAxios, useComponent } from "../../hooks";
import { PageHeader, Loader } from "../../components";
import { botAccessToken, botID } from "../../config/config";

export default function TransactionsDetails() {
  const { axios, loader } = useComponent();

  const templateAxios = useAxios({
    baseURL: "adminV2",
    customAuthToken: botAccessToken,
  });

  const rcAxios = useAxios({
    baseURL: "rcApi",
  });

  const { leadID } = useParams();

  const location = useLocation();

  const navigate = useNavigate();

  const [templates, setTemplates] = useState([]);

  const [authToken, setAuthToken] = useState("");

  const [editState, setEditState] = useState(true);

  const [formData, setFormData] = useState(leadsObject);

  useEffect(() => {
    loader.start(2);

    rcAxios({
      url: "/vahan-login",
      method: "POST",
      data: { email: "admin@verismart.ai", password: "Dolphinchat@123" },
    }).then((response) => {
      if (response.status) {
        setAuthToken(response.data.auth_token);
        loader.apiComplete();
      }
    });

    templateAxios({
      url: "/wa/template/" + botID + "?verified_status=3",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setTemplates(response.data.temp_obj);
      }
    });

    axios({ url: "/leads?transaction_id=" + leadID })
      .then((response) => {
        if (response.status) {
          setFormData(objectFromResponseData(response.data.lead_list));
          loader.apiComplete();
        }
      })
      .catch((err) => console.error(err));
  }, [axios, templateAxios, leadID, loader, rcAxios]);

  return (
    <Loader>
      <PageHeader
        pageName="Lead Details"
        buttons={[
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              navigate(pages.transactionList.route + location.search)
            }
          >
            Back
          </Button>,
        ]}
      />

      <Form
        formData={formData}
        setEditState={setEditState}
        editState={editState}
        templates={templates}
        authToken={authToken}
      />
    </Loader>
  );
}
