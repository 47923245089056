import React from "react";
import { titleCase } from "change-case-all";
import { Grid, IconButton, MenuItem, Tooltip } from "@mui/material";

import TextField from "./../../../components/TextField";
import Label from "../../../components/Label";
import { DeleteOutlined, AddCircleOutlined } from "@mui/icons-material";

export default function FilterDataForm({ dialogFormikProps, filterColumns }) {
  const handleRemoveFilters = (index) => {
    dialogFormikProps.setValues((prevVal) => ({
      ...prevVal,
      ...prevVal.filters?.splice(index, 1),
    }));
  };

  function handleChange(e, index) {
    var value = e.target.value;

    var column = filterColumns.find((item) => item.name === value);

    dialogFormikProps.setValues((prevVal) => ({
      ...prevVal,
      filters: setFilters(prevVal.filters),
    }));

    function setFilters(filters) {
      const columns = [...filters];
      columns[index] = {
        key: value,
        dataType: column.dataType,
        relation: columns[index].relation,
        value: columns[index].value,
        operator: columns[index].operator,
        ...(column.dataType === "list" && { options: column.options }),
      };

      return columns;
    }
  }

  let insuranceProcessFlow = [
    { id: 1, name: "Process Initiated" },
    { id: 2, name: "Notification with Quotes" },
    { id: 3, name: "Notification without Quotes" },
    { id: 4, name: "Notifications Delivered" },
    { id: 5, name: "Notification Read" },
    { id: 6, name: "Interested in Renewal" },
    { id: 7, name: "Went to Partner Page" },
    { id: 8, name: "Quote Viewed" },
    { id: 9, name: "Insurer Selected" },
    { id: 10, name: "KYC Pending" },
    { id: 11, name: "Payment Pending" },
    { id: 11, name: "Payment Retry" },
    { id: 12, name: "Inspection Pending" },
    { id: 13, name: "Inspection In Review" },
    { id: 14, name: "Inspection Rejected" },
    { id: 14, name: "Booked" },
  ];

  return (
    <React.Fragment>
      {dialogFormikProps?.values?.filters.map((_, index) => (
        <React.Fragment key={index}>
          <Grid item xs={5}>
            <Label
              title={"Select Fields"}
              formikProps={dialogFormikProps}
              name={"selectFields"}
            />

            <TextField
              select
              name={"filters[" + index + "].key"}
              label="Select Fields"
              formikProps={dialogFormikProps}
              onChange={(e) => handleChange(e, index)}
            >
              {filterColumns.map((item) => (
                <MenuItem
                  key={item.name}
                  value={item.name}
                  disabled={Boolean(
                    dialogFormikProps?.values.filters.filter((items) => {
                      return items.key === item.name;
                    }).length
                  )}
                >
                  {titleCase(item.label)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {dialogFormikProps?.values?.filters[index].key === "stage" ? (
            <Grid item xs={5}>
              <Label
                title={"Enter Value"}
                formikProps={dialogFormikProps}
                name={"filters[" + index + "].value"}
              />

              <TextField
                select
                name={"filters[" + index + "].value"}
                label="Value"
                formikProps={dialogFormikProps}
              >
                {insuranceProcessFlow.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : (
            <React.Fragment>
              {dialogFormikProps?.values?.filters[index].dataType === "list" ? (
                <Grid item xs={5}>
                  <Label
                    title={"Enter Value"}
                    formikProps={dialogFormikProps}
                    name={"filters[" + index + "].value"}
                  />

                  <TextField
                    select
                    name={"filters[" + index + "].value"}
                    label="Value"
                    formikProps={dialogFormikProps}
                  >
                    {dialogFormikProps?.values?.filters[index].options.map(
                      (option) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.label}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </Grid>
              ) : (
                <React.Fragment>
                  {dialogFormikProps?.values?.filters[index].dataType ===
                  "boolean" ? (
                    <Grid item xs={5}>
                      <Label
                        title={"Enter Value"}
                        formikProps={dialogFormikProps}
                        name={"filters[" + index + "].value"}
                      />

                      <TextField
                        name={"filters[" + index + "].value"}
                        label="Value"
                        select
                        formikProps={dialogFormikProps}
                      >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </TextField>
                    </Grid>
                  ) : (
                    <Grid item xs={5}>
                      <Label
                        title={"Enter Value"}
                        formikProps={dialogFormikProps}
                        name={"filters[" + index + "].value"}
                      />

                      <TextField
                        name={"filters[" + index + "].value"}
                        label="Value"
                        formikProps={dialogFormikProps}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          {dialogFormikProps?.values?.filters.length < 4 && (
            <Grid style={{ alignSelf: "end", textAlign: "end" }} item xs={1}>
              <Tooltip title="Add Filter">
                <IconButton onClick={addFilters} color="primary">
                  <AddCircleOutlined style={{ fontSize: "20px" }} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          {index !== 0 && (
            <Grid style={{ alignSelf: "end", textAlign: "end" }} item xs={1}>
              <Tooltip title="Delete Filter">
                <IconButton onClick={() => handleRemoveFilters(index)}>
                  <DeleteOutlined
                    style={{ fontSize: "20px", color: "#ff0000" }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );

  function addFilters() {
    dialogFormikProps.setValues((prevVal) => ({
      ...prevVal,
      filters: setFilters(prevVal.filters),
    }));

    function setFilters(filters) {
      filters.push({
        key: "",
        value: "",
        operator: "",
        relation: "",
      });
      return filters;
    }
  }
}
