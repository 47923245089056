import { Grid } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";

import Matrix from "./Matrix";
import { useAxios } from "../../../hooks";
import DateFilter from "../../Filter/DateFilter";
import TimeSeriesMetrics from "./TimeSeriesMetrics";
import ComponentLoader from "../../../components/ComponentLoading";

export default function Insight() {
  const axios = useAxios();

  const [loader, setLoader] = useState(false);

  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
    partner: "",
  });

  const [notificationData, setNotificationData] = useState({
    totalNotificationsSent: 0,
    totalNotificationsFailed: 0,
    totalNotificationsDelivered: 0,
    totalNotificationsRead: 0,
  });

  useEffect(() => {
    let url = "/insights/notification-details";

    setLoader(true);

    if (filterData.start_date) {
      url += `?start_date=${filterData.start_date}`;
    }

    if (filterData.end_date) {
      url += url.includes("?")
        ? `&end_date=${filterData.end_date}`
        : `?end_date=${filterData.end_date}`;
    }

    if (filterData.partner) {
      url += url.includes("?")
        ? `&partner=${filterData.partner}`
        : `?partner=${filterData.partner}`;
    }

    axios({
      url: url,
    })
      .then((response) => {
        if (response.status) {
          const { total_sent, total_failed, total_delivered, total_read } =
            response.data;

          setNotificationData({
            totalNotificationsSent: total_sent,
            totalNotificationsFailed: total_failed,
            totalNotificationsDelivered: total_delivered,
            totalNotificationsRead: total_read,
          });

          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  }, [axios, filterData]);
  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        sx={{
          textTransform: "capitalize",
          padding: 10,
          margin: 10,
          display: "flex",
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#f4f7fd",
        }}
      >
        <DateFilter filterData={filterData} setFilterData={setFilterData} />

        {!loader ? (
          <Matrix filterData={filterData} notificationData={notificationData} />
        ) : (
          <ComponentLoader loading={loader} />
        )}

        {/* <OverallNotificationBar
          notificationData={notificationData}
          filterData={filterData}
        /> */}
        <TimeSeriesMetrics filterData={filterData} />
      </Grid>
    </Fragment>
  );
}
