import { makeStyles } from "@mui/styles";
import { Dialog, Grid, Typography } from "@mui/material";

import { Paper } from "../../../../components";
import Table from "../../../leads components/details components/Table";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1300px" },
}));

export default function RCdetailDailog({
  rcDetail,
  rcDetailsDailog,
  setRCDetailsDailog,
}) {
  const classes = useStyles();

  function handleClose() {
    setRCDetailsDailog(false);
  }

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={rcDetailsDailog}
      onClose={handleClose}
    >
      <Paper>
        <Grid item xs={12}>
          <Typography variant="h4" textAlign={"center"}>
            RC Detail
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Table rcDetails={rcDetail[0]} />
        </Grid>
      </Paper>
    </Dialog>
  );
}
