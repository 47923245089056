import { Formik } from "formik";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAxios, useAlert } from "../../../hooks";
import { changePasswordObject, validationSchema } from "./changePasswordObject";

export default function Form() {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={changePasswordObject}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/change-password",
          method: "POST",
          data: {
            old_password: formData.oldPassword,
            new_password: formData.newPassword,
            confirm_password: formData.confirmPassword,
          },
          disableRedirect: true,
        })
          .then(async (res) => {
            if (res.status) {
              dispatch(
                alert({
                  message: "Password changed successfully!",
                  type: "success",
                })
              );
              formData.oldPassword = "";
              formData.newPassword = "";
              formData.confirmPassword = "";
              setSubmitting(false);
            } else {
              setSubmitting(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <FormDisplay formikProps={formikProps} />
        </form>
      )}
    </Formik>
  );
}
