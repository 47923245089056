import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  styled,
  Paper,
  CircularProgress,
  Fade,
} from "@mui/material";

import { useAxios } from "../../../hooks";
import DateFilter from "../../Filter/DateFilter";

const StyledPaper = styled(Paper)(({ theme, backgroundcolor }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  backgroundColor: backgroundcolor,
  width: "15%",
  borderRadius: "16px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const IconWrapper = styled("div")({
  fontSize: 64,
  color: "#00000",
  display: "flex",
  alignItems: "center",
});

const LabelWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
});

const Count = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight: "bold",
  color: "#00000",
  marginLeft: "8px",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: ".75rem",
  color: "#00000",
  textTransform: "uppercase",
  fontWeight: "bolder",
  letterSpacing: "1px",
}));

export default function UserActivity() {
  const axios = useAxios();

  const [loading, setLoading] = useState(false);

  const [userActivity, setUserActivity] = useState([]);

  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    setLoading(true);

    axios({
      url:
        "/insights/user-activity" +
        (filterData.start_date && `?start_date=${filterData.start_date}`) +
        (filterData.end_date && `&end_date=${filterData.end_date}`),
    }).then((response) => {
      if (response.status) {
        setUserActivity(response.data);
      }

      setLoading(false);
    });
  }, [axios, filterData]);

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        sx={{
          textTransform: "capitalize",
          padding: 10,
          margin: 10,
          display: "flex",
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#f4f7fd",
        }}
      >
        <DateFilter filterData={filterData} setFilterData={setFilterData} />

        <Grid container xs={12} justifyContent={"center"}>
          <Typography
            textAlign={"center"}
            sx={{
              fontWeight: 600,
              fontSize: "30px",
              paddingBottom: "15px",
            }}
          >
            User Activity
          </Typography>
        </Grid>

        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? "800ms" : "0ms",
            }}
            unmountOnExit
          >
            <CircularProgress />
          </Fade>
        </Grid>

        {!loading && (
          <Fragment>
            {Object.keys(userActivity).map((key, index) => {
              return (
                <StyledPaper
                  sx={{ border: key === "total_users" && "2px solid red" }}
                >
                  <LabelWrapper>
                    <Label>{key.replace(/_/, " ")}</Label>
                  </LabelWrapper>

                  <IconWrapper>
                    <Count>{userActivity[key]}</Count>
                  </IconWrapper>
                </StyledPaper>
              );
            })}
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
}
