/* Routes for sidebar menu
  For a single entry: {
    name: Entry Display Name,
    icon: Entry Icon,
    ID: dashboardRoutes.[route name].ID,
    path: dashboardRoutes.[route name].path,
  }

  For a folder: {
    name: Folder Display Name,
    icon: Folder Icon,
    ID: random ID for key for component in the loop,
    children: [{
      name: Sub entry name,
      path: dashboardRoutes.[route name].path,
    }],
  }
*/
import { ImUpload2 } from "react-icons/im";
import { IoMdSettings } from "react-icons/io";
import { MdLeaderboard } from "react-icons/md";
import { MdOutlineInsights } from "react-icons/md";
import { FaArrowRightArrowLeft } from "react-icons/fa6";

import {
  BsFillCalendarEventFill,
  BsBroadcast,
  BsFillPeopleFill,
} from "react-icons/bs";

import dashboardRoutes from "../../../router/router components/dashboardLayoutRoutes";

const routes = [
  {
    name: "insight",
    icon: MdOutlineInsights,
    ID: dashboardRoutes.insightRoute.ID,
    path: dashboardRoutes.insightRoute.path,
  },
  {
    name: "Leads",
    icon: MdLeaderboard,
    ID: dashboardRoutes.leadsListsRoute.ID,
    path: dashboardRoutes.leadsListsRoute.path,
  },
  {
    name: "Transaction",
    icon: FaArrowRightArrowLeft,
    ID: dashboardRoutes.transactionListtRoute.ID,
    path: dashboardRoutes.transactionListtRoute.path,
  },

  {
    name: "Process Leads",
    icon: ImUpload2,
    ID: dashboardRoutes.uploadStatusRoute.ID,
    path: dashboardRoutes.uploadStatusRoute.path,
  },
  {
    name: "Broadcast",
    icon: BsBroadcast,
    ID: dashboardRoutes.broadcastRoute.ID,
    path: dashboardRoutes.broadcastRoute.path,
  },

  {
    name: "Settings",
    icon: IoMdSettings,
    ID: dashboardRoutes.settingsRoute.ID,
    path: dashboardRoutes.settingsRoute.path,
  },
  // {
  //   name: "Overall Notification",
  //   icon: MdOutlineInsights,
  //   ID: dashboardRoutes.OverallNotification.ID,
  //   path: dashboardRoutes.OverallNotification.path,
  // },
  // {
  //   name: "Customer Engagement",
  //   icon: FaEnvelopeOpenText,
  //   ID: dashboardRoutes.CustomerEngagement.ID,
  //   path: dashboardRoutes.CustomerEngagement.path,
  // },
];

export default routes;
