import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import "./rc.css";
import Car from "../../../../assets/car.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: "24px 20px",
  },
  "& .MuiDialogActions-root": {
    padding: "24px 20px",
  },
}));

const useStyles = makeStyles(() => ({
  paper: { minWidth: "800px" },
}));

export default function RCDialog({ open, setOpen, rcDetails }) {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.paper }}
      >
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h2">Vehicle Details</Typography>
              <div
                style={{
                  height: "5px",
                  width: "100px",
                  backgroundColor: "#096929",
                  borderRadius: "50px",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3">Vehicle Information</Typography>
            </Grid>

            <Grid item xs={4}>
              <div style={{ height: "150px", width: "200px" }}>
                <img
                  style={{ height: "inherit", width: "inherit" }}
                  src={Car}
                  alt="car"
                />
              </div>
            </Grid>

            <Grid item xs={7}>
              <div style={{ display: "flex", margin: "8px 0" }}>
                <Typography
                  style={{ width: "150px", alignSelf: "center" }}
                  variant="subtitle1"
                  fontWeight={600}
                >
                  Vehicle Number
                </Typography>

                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                  color="#096929"
                >
                  &nbsp; &nbsp; {rcDetails.vehicleNumber || "N.A."}
                </Typography>
              </div>

              <div style={{ display: "flex", margin: "8px 0" }}>
                <Typography
                  style={{ width: "150px", alignSelf: "center" }}
                  variant="subtitle1"
                  fontWeight={600}
                >
                  Vehicle Make
                </Typography>

                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                  color="#096929"
                >
                  &nbsp; &nbsp; {rcDetails.make || "N.A."}
                </Typography>
              </div>

              <div style={{ display: "flex", margin: "8px 0" }}>
                <Typography
                  style={{ width: "150px", alignSelf: "center" }}
                  variant="subtitle1"
                  fontWeight={600}
                >
                  Vehicle Model
                </Typography>

                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                  color="#096929"
                >
                  &nbsp; &nbsp; {rcDetails.model || "N.A."}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3">Insurance Information</Typography>
            </Grid>

            <Grid item xs={12}>
              <div style={{ display: "flex" }}>
                <Typography
                  style={{ width: "300px", alignSelf: "center" }}
                  variant="subtitle1"
                  fontWeight={600}
                >
                  Insurance Policy No.
                </Typography>

                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                  color="#096929"
                >
                  &nbsp; &nbsp; {rcDetails.policyNo || "N.A."}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div style={{ display: "flex" }}>
                <Typography
                  style={{ width: "300px", alignSelf: "center" }}
                  variant="subtitle1"
                  fontWeight={600}
                >
                  Insurance Upto
                </Typography>

                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                  color="#096929"
                >
                  &nbsp; &nbsp; {rcDetails.insuranceUpto || "N.A."}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div style={{ display: "flex" }}>
                <Typography
                  style={{ width: "300px", alignSelf: "center" }}
                  variant="subtitle1"
                  fontWeight={600}
                >
                  Insurance Company
                </Typography>

                <Typography
                  fontWeight={600}
                  variant="subtitle1"
                  color="#096929"
                >
                  &nbsp; &nbsp; {rcDetails.insuranceCompany || "N.A."}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
