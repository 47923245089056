import React, { useState, useEffect, Fragment } from "react";
import { Typography, Grid, Paper, styled } from "@mui/material";
import { useAxios, useComponent } from "../../../hooks";
import Plot from "react-plotly.js";
import Heading from "../../../components/Heading";
import ComponentLoader from "../../../components/ComponentLoading";
const StyledPaper = styled(Paper)(({ theme, cardColor }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  backgroundColor: cardColor,
  minHeight: "120px",
}));

const cardColors = {
  sent: "#3498db",
  delivered: "#2ecc71",
  read: "#f1c40f",
  failed: "#e74c3c",
};

export default function Insight({ filterData, colorMapping, SetStages }) {
  const axios = useAxios();

  const [loader, setLoader] = useState(false);

  const [funnelData, setFunnelData] = useState({
    labels: [],
    values: [],
    stages: [],
  });

  const handleFunnelClick = (event) => {
    if (event.points.length > 0) {
      const pointData = event.points[0];
      const clickedLabel = pointData.y;
      const labelIndex = funnelData.labels.findIndex(
        (label) => label === clickedLabel
      );
      const stage = funnelData.stages[labelIndex];
      let url = `leads/all?stage=${stage}`;
      if (filterData.start_date) {
        url += `&start_date=${filterData.start_date}`;
      }
      if (filterData.end_date) {
        url += `&end_date=${filterData.end_date}`;
      }
      if (filterData.partner) {
        url += `&partner=${filterData.partner}`;
      }
      window.location.href = url;
    }
  };

  useEffect(() => {
    let apiUrl = `/insights/conversion-rates`;
    setLoader(true);

    const queryStringParams = [];
    if (filterData.start_date) {
      queryStringParams.push(`start_date=${filterData.start_date}`);
    }
    if (filterData.end_date) {
      queryStringParams.push(`end_date=${filterData.end_date}`);
    }
    if (filterData.partner) {
      queryStringParams.push(`partner=${filterData.partner}`);
    }

    if (queryStringParams.length > 0) {
      apiUrl += `?${queryStringParams.join("&")}`;
    }

    axios({
      url: apiUrl,
    })
      .then((response) => {
        SetStages(response.data);

        setFunnelData({
          labels: response.data.map((item) => item.label),
          values: response.data.map((item) => item.rate),
          stages: response.data.map((item) => item.stage),
        });

        setLoader(false);
      })
      .catch((err) => setLoader(false));
  }, [axios, filterData]);

  return (
    <Fragment>
      {!loader ? (
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            // justifyContent: "center",
            width: "90%",
            textAlign: "center",
          }}
        >
          <Heading heading="Conversion Funnel" />
          <Plot
            data={[
              {
                type: "funnel",
                y: funnelData.labels,
                x: funnelData.values,
                textposition: "inside",
                textinfo: "y",
                hoverinfo: "y",

                marker: {
                  color: funnelData.labels.map(
                    (label) => colorMapping[label] || "gray"
                  ),
                  line: {
                    width: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
                    color: funnelData.labels.map(
                      (label) => colorMapping[label] || "gray"
                    ),
                  },
                  bordercolor: funnelData.labels.map(
                    (label) => colorMapping[label] || "gray"
                  ),
                },
                textfont: {
                  fontSize: "2rem",
                  fontWeight: "bold",
                },

                connector: { line: { color: "#FFF", width: 1 } },
              },
            ]}
            layout={{
              margin: { l: 125 },
              paper_bgcolor: "#f4f7fd",
              plot_bgcolor: "#f4f7fd",
            }}
            config={{ displayModeBar: true, displaylogo: false }}
            onClick={handleFunnelClick}
          />
        </Grid>
      ) : (
        <ComponentLoader loading={loader} />
      )}
    </Fragment>
  );
}
