import { Button } from "@mui/material";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAxios } from "../../../hooks";
import CustomFilter from "./CustomFilter";
import pages from "../../../constants/pages";
import { DataTable } from "../../../components";

export default function Table({
  customer,
  setCustomer,
  filterData,
  setFilterData,
}) {
  const axios = useAxios();

  const navigate = useNavigate();

  const [filter, setFilter] = useState(false);

  const [tablePages, setTablePages] = useState({
    currentPage: [0],
    rowsPerPage: 51,
  });

  var filterColumns = [
    {
      name: "mobile",
      label: "Mobile",
      dataType: "number",
    },
    {
      name: "vehicle_number",
      label: "Vehicle Number",
      dataType: "number",
    },
    {
      name: "expires_in",
      label: "Expires In",
      dataType: "number",
    },
  ];

  const options = {
    pagination: true,
    print: false,
    rowsPerPageOptions: [25, 50, 100, 150, 200, 250, 500],
    filter: false,

    onChangeRowsPerPage: (numberOfRows) => {
      setTablePages((prev) => ({ ...prev, rowsPerPage: numberOfRows }));
    },

    onChangePage(currentPage) {
      let stage = filterData.filters.filter((item) => {
        return item.key === "stage";
      });
      let moblie = filterData.filters.filter((item) => {
        return item.key === "mobile";
      });

      let vehicleNumber = filterData.filters.filter((item) => {
        return item.key === "vehicle_number";
      });

      let expiresIn = filterData.filters.filter((item) => {
        return item.key === "expires_in";
      });

      if (
        tablePages.currentPage.filter((items) => items === currentPage)
          .length === 0
      ) {
        axios({
          url: `/customer?skip=${currentPage}&limit=${tablePages.rowsPerPage}${
            vehicleNumber.length
              ? "&vehicle_number=" + vehicleNumber[0].value
              : ""
          }${expiresIn.length ? "&expires_in=" + expiresIn[0].value : ""}${
            moblie.length ? "&mobile=" + moblie[0].value : ""
          }`,
        }).then((response) => {
          if (response.status) {
            setCustomer((prevUsers) => [...prevUsers, ...response.data]);
          }
        });
      }

      setTablePages((prev) => ({
        ...prev,
        currentPage: [...prev.currentPage, currentPage],
      }));
    },

    customToolbar: () => (
      <CustomFilter
        filter={filter}
        filterColumns={filterColumns}
        setFilter={setFilter}
        setCustomer={setCustomer}
        filterData={filterData}
        setFilterData={setFilterData}
      />
    ),
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "mobile",
      label: "Mobile Number",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "rc_number",
      label: "Vehicle number",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "stage",
      label: "Stage",
      options: {
        customBodyRender: (value) => {
          if (value === 0) {
            return "Not Initiated";
          }
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "insurance_expiry",
      label: "Insurance Expiry",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "fit_upto",
      label: "RC Expiry",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "last_notification",
      label: "Last Message",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "customer_id",
      label: " ",
      options: {
        customBodyRender: (value) => {
          return (
            <Button
              variant="outlined"
              onClick={() =>
                navigate(
                  pages.LeadsDetails.route +
                    value +
                    (filterData.filters[0].key !== ""
                      ? convertIntoParams(filterData.filters)
                      : "")
                )
              }
            >
              View Details
            </Button>
          );
        },
      },
    },
  ];

  function convertIntoParams(filterData) {
    let params = "?";

    filterData.map((item, index) => {
      params += `${item.key}=${item.value}${
        Boolean(filterData.length > 1 && filterData.length > index + 1)
          ? "&"
          : ""
      }`;
    });

    return params;
  }

  return (
    <Fragment>
      <DataTable columns={columns} options={options} data={customer} />
    </Fragment>
  );
}
