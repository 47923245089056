import React from "react";
import { Grid, FormControl, MenuItem, Typography, Button } from "@mui/material";
import { TextField } from "../../../../../components";

export default function AddBodyVariable({ formikProps, templateVariable }) {
  return (
    <React.Fragment>
      {formikProps.values.templateBody.count_of_variable !== 0 && (
        <Grid item xs={12}>
          <Typography variant="caption1">Body Variable</Typography>
        </Grid>
      )}

      {Array(formikProps.values?.templateBody?.count_of_variable)
        .fill()
        .map((_, index) => (
          <Grid key={index} container spacing={2} xs={12} pl={2}>
            <Grid item mt={1} xs={3}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  fullWidth
                  formikProps={formikProps}
                  label={`Body Variable Value${index + 1}`}
                  name={`templateBody.example[${index}].key`}
                  disabled={Boolean(formikProps.values.ID)}
                  select
                >
                  {templateVariable.map((item, itemIndex) => {
                    let arr = new Array(
                      formikProps.values?.templateBody?.count_of_variable
                    ).fill(0);

                    return (
                      <MenuItem
                        key={itemIndex}
                        disabled={
                          arr.filter((items, index) => {
                            return (
                              formikProps.values?.templateBody?.example[index]
                                ?.key != item
                            );
                          }).length === 1
                        }
                        value={item}
                      >
                        {item.charAt(0).toUpperCase() +
                          item.slice(1).replace(/_/g, " ")}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item mt={1} xs={3}>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label={`Select Body Variable Type ${index + 1}`}
                  disabled={Boolean(formikProps.values.ID)}
                  name={`templateBody.example[${index}].type`}
                  formikProps={formikProps}
                  fullWidth
                  select
                >
                  <MenuItem value={"button"}>Button</MenuItem>

                  <MenuItem value={"variable"}>Variable</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        ))}

      {formikProps.values.templateBody?.count_of_variable !== 0 && (
        <Grid item>
          <Button
            variant={"contained"}
            onClick={() =>
              formikProps.setFieldValue(`templateBody.example`, [
                { type: "", key: "" },
              ])
            }
          >
            Reset
          </Button>
        </Grid>
      )}
    </React.Fragment>
  );
}
