import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAxios } from "../../../hooks";
import CustomFilter from "./CustomFilter";
import pages from "../../../constants/pages";
import convertDate from "../../../utils/convertDate";
import { DataTable, Chip } from "../../../components";
import MessageSent from "../details components/template components/MessageSent";

export default function Table({
  name,
  count,
  leads,
  stage,
  mobile,
  partner,
  end_date,
  setLeads,
  templates,
  filterData,
  start_date,
  setFilterData,
  transaction_id,
}) {
  const axios = useAxios();

  const navigate = useNavigate();

  const [popUp, setPopUp] = useState(false);

  const [filter, setFilter] = useState(false);

  const [numberList, setNumberList] = useState([]);

  const [tablePages, setTablePages] = useState({
    currentPage: [0],
    rowsPerPage: 51,
  });

  var filterColumns = [
    {
      name: "stage",
      label: "Stage",
      dataType: "string",
    },
    {
      name: "mobile",
      label: "Mobile",
      dataType: "number",
    },
    {
      name: "vehicle_number",
      label: "Vehicle Number",
      dataType: "number",
    },
    {
      name: "expires_in",
      label: "Expires In",
      dataType: "number",
    },
  ];

  const columns = [
    {
      name: "customer_id",
      label: "Customer ID",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? value : "Not  Available";
        },
      },
    },
    {
      name: "customer_name",
      label: "Customer Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "insurance_expiry",
      label: "Insurance Expiry",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "rc_number",
      label: "RC Number",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "mobile",
      label: "Mobile Number",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "partner",
      label: "Partner",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "stage",
      label: "Stage",
      options: {
        customBodyRender: (value, val) => {
          return value
            ? processStageHandler(value, val.rowData[8])
            : "Not Available";
        },
      },
    },
    {
      name: "latest_min_quotes",
      label: "Latest Min Quotes",
      options: {
        customBodyRender: (value, val) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "opted_out",
      label: "Opted Out",
      options: {
        customBodyRender: (value) => {
          return (
            <Chip
              type={value ? "error" : "success"}
              label={value ? "Yes" : "No"}
            />
          );
        },
      },
    },

    {
      name: "transaction_id",
      label: "Transaction ID",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "remark",
      label: "Remark",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "last_notification",
      label: "Last Notification",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "transaction_id",
      label: "View Details",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Button
              variant="outlined"
              sx={{ p: "7.5px" }}
              onClick={() =>
                navigate(
                  pages.transactionDetails.route +
                    value +
                    (filterData.filters[0].key !== ""
                      ? convertIntoParams(filterData)
                      : "")
                )
              }
            >
              View Details
            </Button>
          ) : (
            "Not Available"
          );
        },
      },
    },
  ];

  const options = {
    filter: false,

    selectableRows: "multiple",

    rowsPerPageOptions: [25, 50, 100, 150, 200, 250, 500],

    print: false,

    pagination: true,

    onRowSelectionChange: (_, all, rowsSelected) => {
      let arrayNumberList = [];

      for (let row of rowsSelected) {
        arrayNumberList.push(leads[row].transaction_id);
      }

      setNumberList(arrayNumberList);
    },

    onChangeRowsPerPage: (numberOfRows) => {
      setTablePages((prev) => ({ ...prev, rowsPerPage: numberOfRows }));
    },

    onChangePage(currentPage) {
      if (
        tablePages.currentPage.filter((items) => items === currentPage)
          .length === 0
      ) {
        axios({
          url: `/leads?skip=${currentPage}&limit=${
            tablePages.rowsPerPage
          }&transaction_id=${transaction_id}&name=${name}&mobile=${mobile}${
            start_date && `&start_date=${start_date}`
          }${end_date && `&end_date=${end_date}`}${
            stage && `&stage=${stage}`
          }&partner=${partner}`,
        }).then((response) => {
          if (response.status) {
            if (leads.length < count) {
              setLeads((prevUsers) => [
                ...prevUsers,
                ...response.data.lead_list,
              ]);
            }
          }
        });
      }

      setTablePages((prev) => ({
        ...prev,
        currentPage: [...prev.currentPage, currentPage],
      }));
    },

    customToolbar: () => (
      <CustomFilter
        filter={filter}
        filterColumns={filterColumns}
        setFilter={setFilter}
        setLeads={setLeads}
        filterData={filterData}
        setFilterData={setFilterData}
      />
    ),

    customToolbarSelect: (selectedRows) => (
      <Button
        style={{
          marginRight: "24px",
          top: "50%",
          position: "relative",
        }}
        onClick={() => setPopUp(true)}
        startIcon={<Send />}
      >
        Send Message
      </Button>
    ),
  };

  return (
    <Fragment>
      <DataTable
        title={"Total Leads- " + count}
        columns={columns}
        data={leads}
        options={options}
      />

      <MessageSent
        templates={templates}
        popUp={popUp}
        setPopUp={setPopUp}
        numberList={numberList}
      />
    </Fragment>
  );
}

function convertIntoParams(filterData) {
  let params = "?";

  filterData.filters.map((item, index) => {
    params += `${item.key}=${item.value}${
      Boolean(
        filterData.filters.length > 1 && filterData.filters.length > index + 1
      )
        ? "&"
        : ""
    }`;
  });

  if (filterData.startTime) {
    params += "&start_date=" + new Date(filterData.startTime).getTime() / 1000;
  }
  if (filterData.endTime) {
    params += "&end_date=" + new Date(filterData.endTime).getTime() / 1000;
  }

  return params;
}

function processStageHandler(stage, remark) {
  if (stage === 1) {
    return <Chip label={"Process Initiated"} background="Light green"></Chip>;
  }

  if (stage === 2) {
    return <Chip label={"Notification with Quotes"} background="Green"></Chip>;
  }

  if (stage === 3) {
    return (
      <Chip label={"Notification without Quotes"} background="#ffcc00"></Chip>
    );
  }

  if (stage === 4) {
    return <Chip label={"Notifications Delivered"} background="Orange"></Chip>;
  }

  if (stage === 5) {
    return <Chip label={"Notification Read"} background="Orange"></Chip>;
  }

  if (stage === 6) {
    return <Chip label={"Interested in Renewal"} background="Orange"></Chip>;
  }

  if (stage === 7) {
    return <Chip label={"Went to Partner Page"} background="Orange"></Chip>;
  }

  if (stage === 8) {
    return <Chip label={"Quote Viewed"} background="Orange"></Chip>;
  }

  if (stage === 9) {
    return <Chip label={"Insurer Selected"} background="Orange"></Chip>;
  }

  if (stage === 10) {
    return <Chip label={"KYC Pending"} background="Orange"></Chip>;
  }

  if (stage === 11) {
    return <Chip label={"Payment Pending"} background="Red"></Chip>;
  }

  if (stage === 11 && remark === "failed") {
    return <Chip label={"Payment Retry"} background="Red"></Chip>;
  }

  if (stage === 12) {
    return <Chip label={"Inspection Pending"} background="Orange"></Chip>;
  }

  if (stage === 13) {
    return <Chip label={"Inspection In Review"} background="Orange"></Chip>;
  }

  if (stage === 14 && remark === "success") {
    return <Chip label={"Inspection Rejected"} background="Blue"></Chip>;
  } else {
    return <Chip label={"Booked"} background="Blue"></Chip>;
  }
}
