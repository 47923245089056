import { Fragment, useEffect, useState } from "react";

import Form from "./Form";
import useAxios from "./../../hooks/useAxios";
import { PageHeader } from "../../components";
import { objectFromResponse, settingObject } from "./SettingsObject";

export default function Settings() {
  const axios = useAxios();

  const [editState, setEditState] = useState(false);

  const [formData, setFormData] = useState(settingObject);

  useEffect(() => {
    axios({
      url: "/settings/control-data",
      method: "GET",
    }).then((resp) => {
      if (resp.status) {
        setFormData(objectFromResponse(resp.data));
      }
    });
  }, []);

  return (
    <Fragment>
      <PageHeader pageName={"Broadcast Settings"} />

      <Form
        editState={editState}
        setEditState={setEditState}
        formData={formData}
        setFormData={setFormData}
      />
    </Fragment>
  );
}
