import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { Button, Dialog, Grid, MenuItem, Typography } from "@mui/material";

import { useAlert, useAxios } from "../../../../hooks";
import { Paper, TextField } from "../../../../components";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

export default function DeleteRCDetailsDailog({
  deleteRCNumber,
  customerDetails,
  rcDeleteDetailsDailog,
  setRCDeleteDetailsDailog,
}) {
  const classes = useStyles();

  const axios = useAxios();

  const { alert } = useAlert();

  const dispatch = useDispatch();

  function handleClose() {
    setRCDeleteDetailsDailog(false);
  }

  const [reasonData, setReasonData] = useState();

  useEffect(() => {
    axios({
      url: "/settings/control-data",
      method: "GET",
      disableError: true,
    }).then((resp) => {
      if (resp.status) {
        setReasonData(resp.data.vehicle_removal_causes);
      }
    });
  }, [axios]);

  const [formValue, setFormValue] = useState({
    reason: "",
  });

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={rcDeleteDetailsDailog}
      onClose={handleClose}
    >
      <Paper style={{ marginBottom: "0px" }}>
        <Grid item xs={12}>
          <Typography variant="h4" textAlign={"center"}>
            Reason For Delete RC Detail
          </Typography>
        </Grid>

        <Formik
          initialValues={formValue}
          onSubmit={(formData, { setSubmitting }) => {
            axios({
              url: "/customer/" + customerDetails[0].customer_id,
              method: "PUT",
              data: {
                vehicle: {
                  remove: [
                    {
                      rc_number: deleteRCNumber,
                      reason: formData.reason,
                    },
                  ],
                },
              },
              disableRedirect: true,
            }).then((response) => {
              if (response.status) {
                dispatch(
                  alert({
                    type: "success",
                    message: response.message.displayMessage,
                  })
                );
                setSubmitting(false);

                handleClose();
              } else {
                dispatch(
                  alert({
                    type: "error",
                    message: Object.values(response.data.vehicles),
                  })
                );
                setSubmitting(false);
              }
            });
          }}
        >
          {(formikProps) => {
            return (
              <Fragment>
                <Grid item container display={"flex"}>
                  <Grid xs={12}>
                    <TextField
                      formikProps={formikProps}
                      name="reason"
                      label={"Reason"}
                      select
                    >
                      {reasonData.map((item) => {
                        return (
                          <MenuItem value={item.id}>{item.value}</MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>

                  <Grid item container xs={12} mt={3} justifyContent={"end"}>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          setRCDeleteDetailsDailog((prev) => !prev)
                        }
                      >
                        Back
                      </Button>
                    </Grid>

                    <Grid item ml={4}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        fullWidth
                        onClick={formikProps.handleSubmit}
                        disabled={!formikProps.dirty || !formikProps.isValid}
                        loading={formikProps.isSubmitting}
                        color="primary"
                      >
                        Delete RC Number
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            );
          }}
        </Formik>
      </Paper>
    </Dialog>
  );
}
