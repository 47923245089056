import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { Paper, IconButton, Grid, Typography } from "@mui/material";

import DropDownButton from "./navbar components/DropDownButton";

const Wrapper = styled(Paper)`
  height: 64px;
  border-radius: 0px;
`;

const GridContainer = styled(Grid)`
  height: 100%;
  align-items: center;
  padding-inline: 10px;
  gap: 6px;
`;

export default function Navbar({ sidebarState, toggleSideBar }) {
  const navigate = useNavigate();

  return (
    <Wrapper elevation={4}>
      <GridContainer container>
        <Grid item>
          <IconButton onClick={toggleSideBar}>
            {sidebarState.static ? (
              <RiMenuFoldFill color="#096929" />
            ) : (
              <RiMenuUnfoldFill color="#096929" />
            )}
          </IconButton>
        </Grid>

        <Grid item>
          <Typography
            variant="h3"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            color={"#096929"}
          >
            Metassure
          </Typography>
        </Grid>

        <Grid item xs />

        <Grid item>
          <DropDownButton />
        </Grid>
      </GridContainer>
    </Wrapper>
  );
}
