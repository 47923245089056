import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MenuItem as MuiMenuItem, Divider } from "@mui/material";
import styled from "styled-components";

import { signOutMember } from "../../../../redux/slices/memberSlice";
import pages from "../../../../constants/pages";

const MenuItem = styled(MuiMenuItem)`
  min-width: 200px;
`;

export default function DropDownMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Fragment>
      <MenuItem onClick={() => navigate(pages.viewProfile.route)}>
        My Profile
      </MenuItem>

      <MenuItem onClick={() => navigate(pages.changePassword.route)}>
        Change Password
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => dispatch(signOutMember())}>Sign Out</MenuItem>
    </Fragment>
  );
}
