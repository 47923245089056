import React, { useState, useEffect, Fragment } from "react";
import { Typography, Grid, Paper, styled } from "@mui/material";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import DoneIcon from "@mui/icons-material/Done";
import DraftsIcon from "@mui/icons-material/Drafts";
import CancelIcon from "@mui/icons-material/Cancel";
import RefreshIcon from "@mui/icons-material/Refresh";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import PortraitIcon from "@mui/icons-material/Portrait";
import PaymentsIcon from "@mui/icons-material/Payments";
import BarChartIcon from "@mui/icons-material/BarChart";
import HandshakeIcon from "@mui/icons-material/Handshake";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RateReviewIcon from "@mui/icons-material/RateReview";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import WebAssetOffIcon from "@mui/icons-material/WebAssetOff";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CurrencyExchangeIcon from "@mui/icons-material/Autorenew";
import PublishedWithChangesRoundedIcon from "@mui/icons-material/PublishedWithChangesRounded";

import { useAxios } from "../../../hooks";
import ComponentLoader from "../../../components/ComponentLoading";

const StyledPaper = styled(Paper)(({ theme, backgroundcolor }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(3),
  margin: theme.spacing(1),
  backgroundColor: backgroundcolor || "#FFF",
  minHeight: "200px",
  minWidth: "150px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const IconWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "80px",
  color: "#3f51b5", // Change the icon color (you can use any color)
});

const Circle = styled("div")(({ iconColor }) => ({
  width: "70px",
  height: "70px",
  borderRadius: "50%",
  backgroundColor: `rgba(${iconColor}, 0.1)`, // Use rgba() to create a lighter background color
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const LabelWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
});

const Count = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: "bold",
  color: "#00000",
  marginLeft: "8px",
  textAlign: "center",
}));

const Label = styled(Typography)(({ theme, backgroundcolor }) => ({
  fontSize: "1rem",
  fontWeight: "bolder",
  letterSpacing: "1px",
  textAlign: "center",
  color: backgroundcolor,
}));

export default function Insight({ filterData, stages }) {
  const axios = useAxios();

  const [data, setData] = useState({});

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);

    let apiUrl = `/insights/metrics`;

    const queryStringParams = [];
    if (filterData.start_date) {
      queryStringParams.push(`start_date=${filterData.start_date}`);
    }
    if (filterData.end_date) {
      queryStringParams.push(`end_date=${filterData.end_date}`);
    }
    if (filterData.partner) {
      queryStringParams.push(`partner=${filterData.partner}`);
    }

    if (queryStringParams.length > 0) {
      apiUrl += `?${queryStringParams.join("&")}`;
    }

    axios({
      url: apiUrl,
    })
      .then((response) => {
        if (response.status) {
          setLoader(false);
          setData(response.data);
        }
      })

      .catch((err) => console.log(err));
  }, [axios, filterData.start_date, filterData.partner, filterData.end_date]);

  const stageMapping = {};

  stages.map((items) => {
    stageMapping[items.label] = items.stage;
  });

  const iconMapping = {
    "Notifications Delivered": (
      <Circle iconColor="0,165,13">
        <SendRoundedIcon style={{ fontSize: 40, color: "rgb(0 165 13)" }} />
      </Circle>
    ),
    "Notification Read": (
      <Circle iconColor="30, 144, 255">
        <DraftsIcon style={{ fontSize: 40, color: "rgb(30, 144, 255)" }} />
      </Circle>
    ),
    "Went to Partner Page": (
      <Circle iconColor="255,54,244">
        <HandshakeIcon style={{ fontSize: 40, color: "rgb(234 0 221)" }} />
      </Circle>
    ),
    "Process Initiated": (
      <Circle iconColor="86,153,193">
        <PublishedWithChangesRoundedIcon
          style={{ fontSize: 40, color: "rgb(86 153 193)" }}
        />
      </Circle>
    ),
    "Notification with Quotes": (
      <Circle iconColor="255,253,0">
        <WebAssetIcon style={{ fontSize: 40, color: "rgb(255 253 0)" }} />
      </Circle>
    ),

    "Notification without Quotes": (
      <Circle iconColor="255,133,11">
        <WebAssetOffIcon style={{ fontSize: 40, color: "rgb(255 133 11)" }} />
      </Circle>
    ),
    "Interested in Renewal": (
      <Circle iconColor="255,87,34">
        <CurrencyExchangeIcon style={{ fontSize: 40, color: "#FF5722" }} />
      </Circle>
    ),
    Intrested: (
      <Circle iconColor="76,175,80">
        <HomeIcon style={{ fontSize: 40, color: "#4CAF50" }} />
      </Circle>
    ),
    "Quote Viewed": (
      <Circle iconColor="33,150,243">
        <BarChartIcon style={{ fontSize: 40, color: "#2196F3" }} />
      </Circle>
    ),
    "Insurer Selected": (
      <Circle iconColor="255,193,7">
        <LocalOfferIcon style={{ fontSize: 40, color: "#FFC107" }} />
      </Circle>
    ),
    "KYC Pending": (
      <Circle iconColor="244,67,54">
        <PortraitIcon style={{ fontSize: 40, color: "#F44336" }} />
      </Circle>
    ),
    "Payment Pending": (
      <Circle iconColor="156,39,176">
        <PaymentsIcon style={{ fontSize: 40, color: "#9C27B0" }} />
      </Circle>
    ),
    "Payment Retry": (
      <Circle iconColor="96,125,139">
        <RefreshIcon style={{ fontSize: 40, color: "#607D8B" }} />
      </Circle>
    ),
    "Inspection Pending": (
      <Circle iconColor="0,188,212">
        <VisibilityIcon style={{ fontSize: 40, color: "#00BCD4" }} />
      </Circle>
    ),
    "Inspection In Review": (
      <Circle iconColor="63,81,181">
        <RateReviewIcon style={{ fontSize: 40, color: "#3F51B5" }} />
      </Circle>
    ),
    "Inspection Rejected": (
      <Circle iconColor="233,30,99">
        <CancelIcon style={{ fontSize: 40, color: "#E91E63" }} />
      </Circle>
    ),
    Booked: (
      <Circle iconColor="76,175,80">
        <DoneIcon style={{ fontSize: 40, color: "#4CAF50" }} />
      </Circle>
    ),
    "IF NOT FOUND IMG": (
      <Circle iconColor="10,10,10">
        <BarChartIcon style={{ fontSize: 40, color: "#48494a" }} />
      </Circle>
    ),
  };

  const labelKeys = Object.keys(data);
  const firstRow = labelKeys.slice(0, 6);
  const secondRow = labelKeys.slice(6);

  var date = new Date().setDate(new Date().getDate() - 1);
  // space
  return (
    <Fragment>
      {!loader ? (
        <Fragment>
          <Grid container spacing={1}>
            {firstRow.map((label, index) => (
              <Grid item key={index} xs={12} sm={3} md={2}>
                <Link
                  to={`/transaction/all?stage=${stageMapping[label]}${
                    filterData.start_date
                      ? "&start_date=" + filterData.start_date
                      : "&start_date=" +
                        new Date(date).setHours(0, 0, 1, 0) / 1000
                  }${
                    filterData.end_date
                      ? "&end_date=" + filterData.end_date
                      : "&end_date=" +
                        new Date(date).setHours(23, 59, 0, 0) / 1000
                  }`}
                  className="no-decoration-link"
                >
                  <StyledPaper>
                    <IconWrapper>
                      {" "}
                      {iconMapping[label]
                        ? iconMapping[label]
                        : iconMapping["IF NOT FOUND IMG"]}
                    </IconWrapper>
                    <Count>{data[label]}</Count>
                    <Label>{label}</Label>
                  </StyledPaper>
                </Link>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={1} justifyContent="center">
            {secondRow.map((label, index) => (
              <Grid item key={index} xs={12} sm={3} md={2}>
                <Link
                  to={`/transaction/all?stage=${stageMapping[label]}${
                    filterData.start_date
                      ? "&start_date=" + filterData.start_date
                      : "&start_date=" +
                        new Date(date).setHours(0, 0, 1, 0) / 1000
                  }${
                    filterData.end_date
                      ? "&end_date=" + filterData.end_date
                      : "&end_date=" +
                        new Date(date).setHours(23, 59, 0, 0) / 1000
                  }`}
                  className="no-decoration-link"
                >
                  <StyledPaper>
                    {" "}
                    <IconWrapper>
                      {" "}
                      {iconMapping[label]
                        ? iconMapping[label]
                        : iconMapping["IF NOT FOUND IMG"]}
                    </IconWrapper>
                    <Count>{data[label]}</Count>
                    <Label>{label}</Label>
                  </StyledPaper>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Fragment>
      ) : (
        <ComponentLoader loading={loader} />
      )}
    </Fragment>
  );
}
