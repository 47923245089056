import React, { useState, useEffect, Fragment } from "react";
import { Typography, Grid, Paper, styled } from "@mui/material";
import { useAxios, useComponent } from "../../../hooks";
import Plot from "react-plotly.js";
import Heading from "../../../components/Heading";
import ComponentLoader from "../../../components/ComponentLoading";
const StyledPaper = styled(Paper)(({ theme, cardColor }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  backgroundColor: cardColor,
  minHeight: "120px",
}));

const cardColors = {
  sent: "#3498db",
  delivered: "#2ecc71",
  read: "#f1c40f",
  failed: "#e74c3c",
};

export default function Insight({ filterData, colorMapping }) {
  const [funnelData, setFunnelData] = useState({
    labels: [],
    values: [],
    colors: [],
  });

  const [apiData, setApiData] = useState([]);
  const axios = useAxios();

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let apiUrl = `/insights/dropoff-metrics`;

    setLoader(true);

    const queryStringParams = [];
    if (filterData.start_date) {
      queryStringParams.push(`start_date=${filterData.start_date}`);
    }
    if (filterData.end_date) {
      queryStringParams.push(`end_date=${filterData.end_date}`);
    }
    if (filterData.partner) {
      queryStringParams.push(`partner=${filterData.partner}`);
    }

    if (queryStringParams.length > 0) {
      apiUrl += `?${queryStringParams.join("&")}`;
    }

    axios({
      url: apiUrl,
    })
      .then((response) => {
        setApiData(response.data);

        const funnelLabels = response.data.map((item) => item.label);
        const funnelValues = response.data.map((item) => item.rate);
        const colors = funnelLabels.map((label) => colorMapping[label]);

        setFunnelData({
          labels: funnelLabels,
          values: funnelValues,
          colors: colors,
        });

        setLoader(false);
      })
      .catch((err) => console.log(err));
  }, [axios, filterData]);

  const handlePieChartClick = (event) => {
    if (event.points.length > 0) {
      const pointData = event.points[0];
      const clickedLabel = pointData.label;
      const stageNumber = apiData.find(
        (item) => item.label === clickedLabel
      )?.stage;

      if (stageNumber !== undefined) {
        const stage = stageNumber;

        let url = `leads/all?stage=${stage}`;
        if (filterData.start_date) {
          url += `&start_date=${filterData.start_date}`;
        }
        if (filterData.end_date) {
          url += `&end_date=${filterData.end_date}`;
        }
        if (filterData.partner) {
          url += `&partner=${filterData.partner}`;
        }

        window.location.href = url;
      } else {
        console.log("Stage not found for label:", clickedLabel);
      }
    }
  };

  return (
    <Fragment>
      {!loader ? (
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Heading heading="Funnel Drop-Off Analysis" />
          <Plot
            data={[
              {
                type: "pie",
                labels: funnelData.labels,
                values: funnelData.values,
                marker: {
                  colors: funnelData.colors,
                },
                textinfo: "label+value",
                hole: 0.3,
                hoverinfo: "none",
                textposition: "outside",
              },
            ]}
            layout={{
              showlegend: false,
              paper_bgcolor: "#f4f7fd",
              plot_bgcolor: "#f4f7fd",
            }}
            useResizeHandler
            onClick={handlePieChartClick}
          />
        </Grid>
      ) : (
        <ComponentLoader loading={loader} />
      )}
    </Fragment>
  );
}
