import { Fragment } from "react";
import { Grid, MenuItem, Typography } from "@mui/material";

import { FileUpload, TextField } from "./../../../components";
import { useSelector } from "react-redux";

export default function FormOne({ formikProps, uploadStatusType, stages }) {
  const { activeBotID } = useSelector((state) => state.memberDetails);

  return (
    <Grid container mb={5} direction={"column"} xs={12}>
      {uploadStatusType !== undefined ? (
        <Fragment>
          {uploadStatusType ? (
            <Grid container>
              <FileUpload
                label="Upload File"
                name="file_url"
                formikProps={formikProps}
                allowedExtension={".xlsx"}
                maxSize={16}
                folderPath={"broadcast/" + activeBotID + "/"}
              />
            </Grid>
          ) : (
            <Fragment>
              <Grid item mb={3}>
                <Typography variant="h5">Single Upload Status</Typography>
              </Grid>
              <Grid item container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    required
                    name={"name"}
                    label="Name"
                    formikProps={formikProps}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    name={"mobileNo"}
                    label="Mobile No"
                    formikProps={formikProps}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    required
                    name="steps"
                    label="Select Step"
                    formikProps={formikProps}
                  >
                    {stages.map((item) => {
                      return (
                        <MenuItem value={item.label}>{item.label}</MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    name={"registrationNumber"}
                    label="Registration Number"
                    formikProps={formikProps}
                  />
                </Grid>
              </Grid>
            </Fragment>
          )}
        </Fragment>
      ) : (
        ""
      )}
    </Grid>
  );
}
