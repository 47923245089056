import { Grid } from "@mui/material";
import React, { useState, Fragment } from "react";

import Matrix from "./Matrix";
import DateFilter from "../../Filter/DateFilter";
import ConversionFunnel from "./ConversionFunnel";
import FunnelDropOffAnalysis from "./FunnelDropOffAnalysis";

export default function Insight() {
  const [stages, SetStages] = useState([]);

  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
    partner: "",
  });

  const colorMapping = {
    "Interested in Renewal": "#a9d6e5",
    "Quote Viewed": "#89c2d9",
    "Insurer Selected": "#61a5c2",
    "KYC Pending": "#468faf",
    "Payment Pending": "#2c7da0",
    "Payment Retry": "#2a6f97",
    "Inspection Pending": "#014f86",
    "Inspection In Review": "#01497c",
    "Inspection Rejected": "#013a63",
    Booked: "#012a4a",
  };

  const pieMapping = {
    "Interested in Renewal": "#FF5733", // Light Coral
    "Quote Viewed": "#FFAC33", // Light Gold
    "Insurer Selected": "#33FF57", // Mint Green
    "KYC Pending": "#FF33A0", // Orchid
    "Payment Pending": "#33CCFF", // Dodger Blue
    "Payment Retry": "#FF6B81", // Salmon Pink
    "Inspection Pending": "#A99AED", // Lavender Purple
    "Inspection In Review": "#33FFD5", // Light Aqua
    "Inspection Rejected": "#FF3333", // Light Red
    Booked: "#2ecc71", // Emerald Green
  };

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        sx={{
          textTransform: "capitalize",
          padding: 10,
          margin: 10,
          display: "flex",
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#f4f7fd",
        }}
      >
        <DateFilter filterData={filterData} setFilterData={setFilterData} />

        <Matrix filterData={filterData} stages={stages} />

        <Grid container item spacing={1} mt={20}>
          <ConversionFunnel
            filterData={filterData}
            colorMapping={colorMapping}
            SetStages={SetStages}
          />
          <FunnelDropOffAnalysis
            filterData={filterData}
            colorMapping={pieMapping}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
