import { Fragment } from "react";

import { DataTable } from "../../../../components";
import { convertDate } from "../../../../utils";
import { Button } from "@mui/material";
import pages from "../../../../constants/pages";
import { useNavigate } from "react-router-dom";

export default function TransactionTable({ transactions }) {
  const navigate = useNavigate();

  const options = {};

  const columns = [
    {
      name: "created_at",
      label: "Transaction Start Date",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "current_stage",
      label: "Stage",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "last_notification",
      label: "Last Notification Sent",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "total_notifications",
      label: "Number Notification Sent",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "insurance_expiry",
      label: "Insurance Expiry Date",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "_id",
      label: " ",
      options: {
        customBodyRender: (value) => {
          return (
            <Button
              variant="outlined"
              onClick={() => navigate(pages.transactionDetails.route + value)}
            >
              View Details
            </Button>
          );
        },
      },
    },
  ];

  return (
    <Fragment>
      <DataTable columns={columns} options={options} data={transactions} />
    </Fragment>
  );
}
