import * as Yup from "yup";
import { Formik } from "formik";
import { Fragment, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

import FormOne from "./FormOne";
import { useAxios } from "../../../hooks";
import { TextField } from "../../../components";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "750px" },
}));

export default function RequestUploadStatus({
  setuploadStatusRequest,
  uploadStatusRequest,
  stages,
}) {
  const axios = useAxios();

  const classes = useStyles();

  const [uploadStatusType, setUploadStatusType] = useState();

  const [formData, setFormData] = useState({
    name: "",
    mobileNo: "",
    registrationNumber: "",
    steps: "",
    file_url: "",
  });

  const validationSchema = Yup.object().shape({
    ...(uploadStatusType
      ? {
          file_url: Yup.string().required("File URL is required."),
        }
      : {
          name: Yup.string().required("Name is required."),

          mobileNo: Yup.string().required("Mobile Number is required."),

          registrationNumber: Yup.string().required(
            "Registration Number is required."
          ),

          steps: Yup.string().required("Steps is required."),
        }),
  });

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={uploadStatusRequest}
      onClose={() => setuploadStatusRequest(false)}
    >
      <Grid
        container
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        pl={10}
      >
        <Grid item>
          <Typography variant="h5">Request Create Leads</Typography>
        </Grid>
        <Grid item>
          <IconButton
            disableRipple
            style={{ float: "right", padding: "16px 24px" }}
            onClick={() => {
              setFormData({
                name: "",
                mobileNo: "",
                registrationNumber: "",
                file_url: "",
                steps: "",
              });
              setUploadStatusType(undefined);
              setuploadStatusRequest(false);
            }}
          >
            <Cancel />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      <DialogContent>
        <Formik
          initialValues={formData}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(formData) => {
            axios({
              url: "/leads/sheet",
              method: "POST",
              data: {
                ...(formData.name &&
                  uploadStatusType === false && {
                    Name: formData.name,
                    Step: formData.steps,
                    MobileNo: formData.mobileNo,
                    RegistrationNumber: formData.registrationNumber,
                  }),
                ...(formData.file_url &&
                  uploadStatusType === true && {
                    file_url: formData.file_url,
                  }),
              },
            }).then((response) => {
              if (response.status) {
                setFormData({
                  name: "",
                  mobileNo: "",
                  registrationNumber: "",
                  file_url: "",
                  steps: "",
                });
                setUploadStatusType(undefined);
                setuploadStatusRequest(false);
              }
            });
          }}
        >
          {(formikProps) => {
            return (
              <Fragment>
                <Grid container mb={3} xs={6}>
                  <TextField
                    select
                    required
                    name="uploadStatusType"
                    label="Select Create Leads Type"
                    onChange={(e) => {
                      setUploadStatusType(e.target.value);
                    }}
                  >
                    <MenuItem value={false}>Single Upload Status</MenuItem>
                    <MenuItem value={true}>Bulk Upload Status</MenuItem>
                  </TextField>
                </Grid>
                <Grid display={"flex"}>
                  <FormOne
                    formikProps={formikProps}
                    uploadStatusType={uploadStatusType}
                    stages={stages}
                  />
                </Grid>
                <Grid
                  item
                  display={"flex"}
                  justifyContent={"space-between"}
                  style={{ textAlign: "end" }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setFormData({
                        name: "",
                        mobileNo: "",
                        registrationNumber: "",
                        file_url: "",
                        steps: "",
                      });
                      setUploadStatusType(undefined);
                      setuploadStatusRequest(false);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    onClick={formikProps.handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={!formikProps.dirty || !formikProps.isValid}
                  >
                    Generate Create Leads Request
                  </Button>
                </Grid>
              </Fragment>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
