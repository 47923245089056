import { useState } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  // Link,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Paper, TextField } from "../../../components";
import { useNavigate } from "react-router-dom";

export default function FormDisplay({ formikProps }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Paper style={{ width: "600px" }}>
      <Grid item xs={12}>
        <Typography variant="h2" align="center">
          Metassure
        </Typography>

        <Typography variant="body1" align="center">
          Sign in to your account to continue!
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          name="email"
          label="Email Address"
          formikProps={formikProps}
          autoComplete="off"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type={showPassword ? "text" : "password"}
          name="password"
          label="Password"
          formikProps={formikProps}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item>
        <FormControlLabel
          control={
            <Checkbox name="rememberMe" onChange={formikProps.handleChange} />
          }
          label="Remember me"
        />
      </Grid>

      <Grid item xs />

      {/* <Grid item alignSelf="center">
        <Link href="/forgot-password">Forgot Password?</Link>
      </Grid> */}

      <Grid item xs={12}>
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.dirty || !formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Sign In
        </LoadingButton>
      </Grid>
    </Paper>
  );
}
