import { Formik } from "formik";

import FormDisplay from "./FormDisplay";
import { validationSchema } from "./SettingsObject";
import { useAxios, useComponent } from "../../hooks";

export default function Form({ editState, setEditState, formData }) {
  const axios = useAxios();

  const { alert } = useComponent();

  return (
    <Formik
      initialValues={formData}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(formData) => {
        axios({
          url: "/settings/partner-notifications",
          method: "PATCH",
          data: {
            policy_bazaar: Number(formData.policyBazaarPercent),
            turtle_mint: Number(formData.turtleMintPercent),
          },
        }).then((res) => {
          if (res.status) {
            alert.success(res.message.displayMessage);
          }
        });

        axios({
          url: "/settings/daily-limit/" + formData.dailyNotificationLimit,
          method: "PATCH",
        }).then((res) => {
          if (res.status) {
            alert.success(res.message.displayMessage);
          }
        });

        axios({
          url: "/settings/broadcast-control",
          method: "PATCH",
          data: {
            queue_days_before: Number(formData.buildQueueDaysBefore),
            days_list: formData.notificationDays,
            is_testing: formData.isTesting,
            ...(formData.isTesting && {
              testing_mobile: formData.testingMobile,
            }),
            banner_image_url: formData.bannerImages,
          },
        }).then((res) => {
          if (res.status) {
            alert.success(res.message.displayMessage);
          }
        });
      }}
    >
      {(formikProps) => {
        return (
          <FormDisplay
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
          />
        );
      }}
    </Formik>
  );
}
