import { useState } from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  // Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Paper, TextField } from "../../../components";

export default function FormDisplay({ formikProps }) {
  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
    confirm: false,
  });

  return (
    <Paper style={{ width: "600px" }}>
      <Grid item xs={12}>
        <Typography variant="h2" align="center">
          Change Password
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type={showPassword.old ? "text" : "password"}
          name="oldPassword"
          label="Old Password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowPassword({ old: !showPassword.old })}
                >
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type={showPassword.new ? "text" : "password"}
          name="newPassword"
          label="New Password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowPassword({ new: !showPassword.new })}
                >
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type={showPassword.confirm ? "text" : "password"}
          name="confirmPassword"
          label="Confirm Password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() =>
                    setShowPassword({ confirm: !showPassword.confirm })
                  }
                >
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs />

      <Grid item xs={12}>
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.dirty || !formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Change Password
        </LoadingButton>
      </Grid>
    </Paper>
  );
}
