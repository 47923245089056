import { useLocation } from "react-router-dom";

export default function useQueryParams() {
  const { search } = useLocation();

  const queryParams = search.slice(1).split("&");

  const queryParamsObject = {};

  for (let queryParam of queryParams) {
    queryParamsObject[queryParam.split("=")[0]] = queryParam.split("=")[1];
  }

  return queryParamsObject;
}
