import React from "react";
import { Typography, Grid, Paper, Box, styled } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DescriptionIcon from "@mui/icons-material/Description";
import PaymentIcon from "@mui/icons-material/Payment";
import { Link } from "react-router-dom";

const StyledPaper = styled(Paper)(({ theme, backgroundcolor }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  backgroundColor: backgroundcolor,
  width: "90%",
  borderRadius: "16px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const IconWrapper = styled("div")({
  fontSize: 64,
  color: "#00000",
  marginTop: "auto",
  marginBottom: "8px",
  display: "flex",
  alignItems: "center",
});

const LabelWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
});

const Count = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  color: "#00000",
  marginLeft: "8px",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  color: "#00000",
  textTransform: "uppercase",
  fontWeight: "bolder",
  letterSpacing: "1px",
}));

export default function Insight({ notificationData, filterData }) {
  const insightData = [
    {
      label: "Sent",
      icon: <AssignmentIndIcon />,
      count: notificationData.totalNotificationsSent || 0,
      backgroundColor: "#f06292",
    },
    {
      label: "Failed",
      icon: <VisibilityIcon />,
      count: notificationData.totalNotificationsFailed || 0,
      backgroundColor: "#64b5f6",
    },
    {
      label: "Delivered",
      icon: <DescriptionIcon />,
      count: notificationData.totalNotificationsDelivered || 0,
      backgroundColor: "#ffb74d",
    },
    {
      label: "Read",
      icon: <PaymentIcon />,
      count: notificationData.totalNotificationsRead || 0,
      backgroundColor: "#81c784",
    },
  ];

  return (
    <Grid container spacing={2} justifyContent="space-evenly">
      {insightData.map((insight) => (
        <Grid key={insight.label} item xs={12} sm={3} md={2}>
          {/* <Link
            to={`/leads/all?notifications=${insight.label.toLowerCase()}${
              filterData.start_date
                ? `&start_date=${filterData.start_date}`
                : ""
            }${filterData.end_date ? `&end_date=${filterData.end_date}` : ""}${
              filterData.partner ? `&partner=${filterData.partner}` : ""
            }`}
            className="no-decoration-link"
          > */}
          <StyledPaper backgroundcolor={insight.backgroundColor}>
            <LabelWrapper>
              <Label>{insight.label}</Label>
            </LabelWrapper>
            <IconWrapper>
              {insight.icon}
              <Count>{insight.count}</Count>
            </IconWrapper>
          </StyledPaper>
          {/* </Link> */}
        </Grid>
      ))}
    </Grid>
  );
}
