import * as Yup from "yup";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { Fragment, useState } from "react";
import { Button, Grid } from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { useAlert, useAxios } from "../../../../hooks";
import ViewConversation from "../View Conversation/ViewConversation";
import { ComponentHeader, Paper, TextField } from "../../../../components";
import ENV, { botID } from "../../../../config/config";

export default function CustomerDetails({
  formValue,
  editState,
  setEditState,
  customerDetails,
}) {
  const axios = useAxios();

  const navigate = useNavigate();

  const { alert } = useAlert();

  const dispatch = useDispatch();

  const [viewConversation, setViewConversation] = useState(false);

  return (
    <Paper>
      <Formik
        initialValues={formValue}
        validationSchema={validationSchema}
        onSubmit={(formData, { setSubmitting }) => {
          axios({
            url: `/customer/` + customerDetails[0].customer_id,
            method: "PUT",
            data: { name: formData.name, mobile: formData.mobile },
            disableRedirect: true,
          }).then((response) => {
            if (response.status) {
              dispatch(
                alert({
                  type: "success",
                  message: response.message.displayMessage,
                })
              );
            }
            setSubmitting(false);
          });
        }}
      >
        {(formikProps) => {
          return (
            <Fragment>
              <Grid item xs={12}>
                <ComponentHeader
                  title="Lead Details"
                  buttons={[
                    <Link
                      to={`http://${
                        ENV === "dev" ? "dev." : ""
                      }admin.dolphinchat.ai/chats/${
                        botID + "*_*" + customerDetails[0].mobile
                      }`}
                      target="_blank"
                    >
                      {" "}
                      Go to Live Chat
                    </Link>,

                    <ViewConversationButton />,

                    <LoadingButton
                      variant="contained"
                      onClick={() => {
                        !editState && formikProps.handleSubmit();
                        setEditState((prev) => !prev);
                      }}
                      loading={formikProps.isSubmitting}
                    >
                      {`${editState ? "Edit" : "Submit"}`}
                    </LoadingButton>,
                    !editState && (
                      <Button
                        variant="contained"
                        onClick={() => setEditState((prev) => !prev)}
                      >
                        Cancel
                      </Button>
                    ),
                  ]}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="name"
                  label="Customer Name"
                  disabled={editState}
                  formikProps={formikProps}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="mobile"
                  label="Mobile Number"
                  disabled={editState}
                  formikProps={formikProps}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="source"
                  label="Source"
                  disabled
                  formikProps={formikProps}
                  value={customerDetails[0]}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="lastNotification"
                  label="Last Notification"
                  disabled
                  formikProps={formikProps}
                  value={customerDetails[0]?.last_notification}
                />
              </Grid>
            </Fragment>
          );
        }}
      </Formik>

      <ViewConversation
        viewConversation={viewConversation}
        setViewConversation={setViewConversation}
        mobileNumber={customerDetails[0]?.mobile}
      />
    </Paper>
  );

  function ViewConversationButton() {
    return (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setViewConversation(true)}
      >
        View Conversation
      </Button>
    );
  }
}

const validationSchema = Yup.object().shape({
  mobile: Yup.number().max(9999999999, "Mobile Number should be a 10-digit"),
});
