import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { FilterList, Sync } from "@mui/icons-material";
import { Badge, IconButton, Tooltip } from "@mui/material";

import { useAxios } from "../../../hooks";
import FilterDataDialog from "./FilterDataDialog";
import Refresh from "../../../assets/refresh.gif";

const defaultToolbarStyles = {
  iconButton: {},
};

function CustomFilter({
  filter,
  setLeads,
  setFilter,
  filterData,
  setFilterData,
  filterColumns,
}) {
  const axios = useAxios();

  const [refresh, setRefresh] = useState(false);

  function handleClick() {
    setFilter(true);
  }

  //Space
  function refreshLeads() {
    setRefresh(true);
    axios({
      url: `/leads?skip=1&limit=101`,
    }).then((response) => {
      if (response.status) {
        setLeads(response.data.lead_list);
        setRefresh(false);
      }
    });
  }

  return (
    <React.Fragment>
      <Badge
        badgeContent={filterData.filters[0].key ? filterData.filters.length : 0}
        color="primary"
      >
        <Tooltip title={"Filter Data"}>
          <IconButton
            className={defaultToolbarStyles.iconButton}
            onClick={handleClick}
          >
            <FilterList className={defaultToolbarStyles.deleteIcon} />
          </IconButton>
        </Tooltip>
      </Badge>

      <Tooltip title={"Refresh Data"}>
        <IconButton
          className={defaultToolbarStyles.iconButton}
          onClick={refreshLeads}
        >
          {refresh ? (
            <img style={{ height: "22px" }} src={Refresh} alt="refresh" />
          ) : (
            <Sync className={defaultToolbarStyles.deleteIcon} />
          )}
        </IconButton>
      </Tooltip>

      <FilterDataDialog
        filter={filter}
        setFilter={setFilter}
        filterColumns={filterColumns}
        setLeads={setLeads}
        filterData={filterData}
        setFilterData={setFilterData}
      />
    </React.Fragment>
  );
}

export default withStyles(defaultToolbarStyles, { name: "CustomFilter" })(
  CustomFilter
);
