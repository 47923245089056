import * as Yup from "yup";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { Fragment, useState } from "react";

import ConfirmDialog from "./Confirm";
import { FileUpload, TextField } from "../../../../components";
import { useAlert, useAxios } from "../../../../hooks";
import Label from "./../../../../components/Label";

const useStyles = makeStyles(() => ({ paper: { minWidth: "700px" } }));

export default function UpdateStage({
  parentFormikProps,
  updateStageDialog,
  setUpdateStageDialog,
  transactionID,
}) {
  const axios = useAxios();

  const classes = useStyles();

  const { alert } = useAlert();

  const dispatch = useDispatch();

  const [confirm, setConfirm] = useState(false);

  const [loading, setLoading] = useState(false);

  const steps = [
    "Process Initiated",
    "Notification with Quotes",
    "Notification without Quotes",
    "Notifications Delivered",
    "Notification Read",
    "Interested in Renewal",
    "Went to Partner Page",
    "Quote Viewed",
    "Insurer Selected",
    "KYC Pending",
    "Payment Pending",
    "Payment Retry",
    "Inspection Pending",
    "Inspection In Review",
    "Inspection Rejected",
    "Booked",
  ];

  const [formData, setFormData] = useState({
    stage: "",
    policy_doc: "",
    policy_no: "",
  });

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={updateStageDialog}
      onClose={() => setUpdateStageDialog(false)}
    >
      <DialogTitle sx={{ fontWeight: "900" }}>Update Stage</DialogTitle>

      <DialogContent>
        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={(formData) => {
            setLoading(true);
            axios({
              url: "/leads/status/" + transactionID,
              method: "PATCH",
              disableRedirect: true,
              data: {
                current_stage: formData.stage,
                ...(formData.policy_doc && { policy_doc: formData.policy_doc }),
                ...(formData.policy_no && { policy_no: formData.policy_no }),
              },
            }).then((response) => {
              if (response.status) {
                dispatch(
                  alert({
                    type: "success",
                    message: response.message.displayMessage,
                  })
                );
                setConfirm(false);
                setUpdateStageDialog(false);
              } else {
                dispatch(
                  alert({
                    type: "error",
                    message: response.message.displayMessage,
                  })
                );
              }
              setLoading(false);
            });
          }}
        >
          {(formikProps) => {
            return (
              <Fragment>
                <Grid container justifyContent={"space-between"}>
                  <Grid item xs={12} pt={2}>
                    <Label
                      required
                      title="Select Stage"
                      formikProps={formikProps}
                      name={"stage"}
                    />
                  </Grid>

                  <Grid item xs={12} pb={2}>
                    <TextField
                      label="Select Stage"
                      name="stage"
                      formikProps={formikProps}
                      select
                    >
                      {steps.map((items, index) => {
                        let currentStage = parentFormikProps.values.stage;

                        if (currentStage === 14) {
                          if (parentFormikProps.values.remark === "failed") {
                            currentStage = 14;
                          } else {
                            currentStage = 15;
                          }
                        }

                        if (currentStage === 11) {
                          if (parentFormikProps.values.remark === "failed") {
                            currentStage = 12;
                          } else {
                            currentStage = 11;
                          }
                        }
                        console.log(
                          currentStage,
                          "currentStage",
                          parentFormikProps.values.remark === "failed"
                        );
                        return (
                          <MenuItem
                            name={items}
                            value={items}
                            disabled={index + 1 < currentStage}
                          >
                            {items}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>

                  {formikProps.values.stage === "Booked" && (
                    <Fragment>
                      <Grid item xs={12} pt={2}>
                        <Label
                          title="Select Policy Doc"
                          formikProps={formikProps}
                          required={formikProps.values.stage === "Booked"}
                          name={"policy_doc"}
                        />
                      </Grid>

                      <Grid item xs={12} pt={2}>
                        <FileUpload
                          required={formikProps.values.stage === "Booked"}
                          name="policy_doc"
                          formikProps={formikProps}
                          placeholder="Select Policy Doc"
                          allowedExtension=".jpg,.jpeg,.png,.gif"
                          folderPath={"documents/" + new Date().getTime() + "_"}
                        />
                      </Grid>
                    </Fragment>
                  )}

                  {formikProps.values.stage === "Booked" && (
                    <Fragment>
                      <Grid item xs={12} pt={2}>
                        <Label
                          title="Enter Policy No"
                          formikProps={formikProps}
                          required={formikProps.values.stage === "Booked"}
                          name={"policy_doc"}
                        />
                      </Grid>

                      <Grid item xs={12} pt={2} pb={2}>
                        <TextField
                          required={formikProps.values.stage === "Booked"}
                          label="Policy No"
                          name="policy_no"
                          formikProps={formikProps}
                        ></TextField>
                      </Grid>
                    </Fragment>
                  )}

                  <Grid item>
                    <LoadingButton
                      variant="contained"
                      onClick={() => setConfirm(true)}
                      disabled={!formikProps.dirty || !formikProps.isValid}
                      sx={{ height: "100%" }}
                    >
                      Update Stage
                    </LoadingButton>

                    <ConfirmDialog
                      loading={loading}
                      confirm={confirm}
                      setConfirm={setConfirm}
                      formikProps={formikProps}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const validationSchema = Yup.object().shape({
  stage: Yup.string().required("Stage is Required"),

  policy_no: Yup.string().when("stage", {
    is: (val) => val === "Booked",
    then: Yup.string().required(),
  }),

  policy_doc: Yup.string().when("stage", {
    is: (val) => val === "Booked",
    then: Yup.string().required(),
  }),
});
