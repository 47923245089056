import React, { useState, useEffect, Fragment } from "react";
import { useAxios } from "../../../hooks";
import { CircularProgress, Fade, Grid, Typography } from "@mui/material";
import Tree from "react-d3-tree";
import DateFilter from "../../Filter/DateFilter";
import Heading from "../../../components/Heading";

const gridItemStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "#FFF",
  backgroundColor: "rgb(9, 105, 41)",
  borderRadius: "0px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.05)",
  },
  height: "200px", // Ensure equal heights
};

export default function Insight() {
  const axios = useAxios();

  const [pbApis, setPbApis] = useState({});

  const [treeData, setTreeData] = useState({});

  const [loading, setLoading] = useState(false);

  const [deliveryStatus, setDeliveryStatus] = useState({});

  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
    partner: "",
  });

  useEffect(() => {
    setLoading(true);

    let url = "/insights/notification/report";

    if (filterData.start_date) {
      url += `?start_date=${filterData.start_date}`;
    }

    if (filterData.end_date) {
      url += url.includes("?")
        ? `&end_date=${filterData.end_date}`
        : `?end_date=${filterData.end_date}`;
    }

    if (filterData.partner) {
      url += url.includes("?")
        ? `&partner=${filterData.partner}`
        : `?partner=${filterData.partner}`;
    }

    axios({
      url: url,
    })
      .then((response) => {
        if (response.status) {
          const data = response.data.total_initiated;
          setDeliveryStatus(response.data.delivery_status);

          setPbApis(response.data.pb_apis);
          setTreeData({
            name: `Total Initiated`,
            attributes: { count: data.count },
            children: [
              {
                name: `User Opted Out`,
                attributes: { count: data.breakup.user_opted_out.count },
              },
              {
                name: `Vehicle Life Ended`,
                attributes: { count: data.breakup.vehicle_life_ended.count },
              },
              {
                name: `Vehicle Not Lmv`,
                attributes: { count: data.breakup.vehicle_not_lmv.count },
              },
              {
                name: `Not In Opted City Tier`,
                attributes: {
                  count: data.breakup.not_in_opted_city_tier.count,
                },
              },
              {
                name: `Not In Opted Cost Class`,
                attributes: {
                  count: data.breakup.not_in_opted_cost_class.count,
                },
              },
              {
                name: `Crucial Details Missing`,
                attributes: {
                  count: data.breakup.crucial_details_missing.count,
                },
              },

              {
                name: `send Attempts`,
                attributes: { count: data.breakup.send_attempts.count },
                children: [
                  {
                    name: `Failed To Deliver`,
                    attributes: {
                      count:
                        data.breakup.send_attempts.breakup.failed_to_deliver
                          .count,
                    },
                  },
                  {
                    name: `With Quotes `,
                    attributes: {
                      count:
                        data.breakup.send_attempts.breakup.with_quotes.count,
                    },
                  },
                  {
                    name: `Without Quotes `,
                    attributes: {
                      count:
                        data.breakup.send_attempts.breakup.without_quotes.count,
                    },
                    children: [
                      {
                        name: `Pb Api Failed `,
                        attributes: {
                          count:
                            data.breakup.send_attempts.breakup.without_quotes
                              .breakup.pb_api_failed.count,
                        },
                      },
                      {
                        name: `Vehicle Match Not Found`,
                        attributes: {
                          count:
                            data.breakup.send_attempts.breakup.without_quotes
                              .breakup.vehicle_match_not_found.count,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                name: `Vehicle older than Allowed`,
                attributes: {
                  count: data.breakup.vehicle_older_than_allowed.count,
                },
              },
            ],
          });
        }

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [axios, filterData]);

  const renderGridItem = (title, data) => (
    <Grid
      item
      xs={12}
      md={3}
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        ...gridItemStyle,
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#FFF",

          fontSize: "1rem",
          textTransform: "uppercase",
          fontWeight: "bolder",
          letterSpacing: "1px",
        }}
      >
        {title}
      </Typography>
      <Grid container direction="column" alignItems="flex-start">
        {Object.entries(data).map(([key, value]) => (
          <Grid container direction="row" key={key}>
            <Typography sx={{ marginBottom: 2 }}>
              {key}: {value ?? "N/A"}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  return (
    <Fragment>
      <Grid
        container
        spacing={5}
        sx={{
          textTransform: "capitalize",
          padding: 10,
          margin: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#f4f7fd",
        }}
      >
        <Heading heading="Sent Notifications Report" />

        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? "800ms" : "0ms",
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>

        {!loading && (
          <Grid container item spacing={2}>
            <DateFilter filterData={filterData} setFilterData={setFilterData} />
            <Grid item xs={12}>
              <div
                id="treeWrapper"
                style={{ width: "100%", height: "550px", textAlign: "center" }}
              >
                <Tree
                  data={treeData}
                  orientation="vertical"
                  translate={{ x: 500, y: 100 }}
                  separation={{ siblings: 1.5, nonSiblings: 1.5 }}
                  nodeSvgShape={{ shape: "dot", shapeProps: { r: 10 } }}
                  pathFunc="step"
                  rootNodeClassName="node__root"
                  branchNodeClassName="node__branch"
                  leafNodeClassName="node__leaf"
                  // zoomable={0}
                />
              </div>
            </Grid>

            {deliveryStatus &&
              renderGridItem("Delivery Status", {
                Delivered: deliveryStatus.delivered,
                Read: deliveryStatus.read,
                "User Responded": deliveryStatus.user_responded,
              })}
            {pbApis.total_calls &&
              renderGridItem("Total Calls", pbApis.total_calls)}
            {pbApis.successes && renderGridItem("Successes", pbApis.successes)}
            {pbApis.fails && renderGridItem("Fails", pbApis.fails)}
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
}
