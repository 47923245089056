import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Table from "./leads list/Table";
import { Loader, PageHeader } from "../../components";
import { useAxios, useComponent, useQueryParams } from "../../hooks";

export default function Leads() {
  const axios = useAxios();

  const location = useLocation();

  const { loader } = useComponent();

  const paramsData = useQueryParams();

  const [customer, setCustomer] = useState([]);

  const [filterData, setFilterData] = useState({
    filters: [
      {
        key: "",
        value: "",
        operator: "",
        relation: "",
      },
    ],
  });

  useEffect(() => {
    loader.start();

    let filterData = [];

    Object.keys(paramsData).map((items) => {
      filterData.push({
        key: items,
        dataType: "number",
        relation: "",
        value: paramsData[items],
        operator: "",
      });
    });

    setFilterData({ filters: filterData });

    axios({
      url: "/customer" + (location.search ? location.search : ""),
    }).then((response) => {
      if (response.status) {
        setCustomer(response.data);
        loader.apiComplete();
      }
      loader.stop();
    });
  }, [axios, loader]);

  return (
    <Loader>
      <PageHeader pageName={"Leads List"} />

      <Table
        customer={customer}
        setCustomer={setCustomer}
        filterData={filterData}
        setFilterData={setFilterData}
      />
    </Loader>
  );
}
