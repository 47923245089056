import { Grid } from "@mui/material";
import Plotly from "react-plotly.js";
import React, { useState, useEffect, Fragment } from "react";

import { useAxios } from "../../../hooks";
import Heading from "../../../components/Heading";
import ComponentLoader from "../../../components/ComponentLoading";

export default function VisitorGraph({ filterData }) {
  const axios = useAxios();

  const [labels, setLabels] = useState([]);

  const [plotlyData, setPlotlyData] = useState(null);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let apiUrl = `/insights/notification_metrics`;
    setLoader(true);

    const queryStringParams = [];
    if (filterData.start_date) {
      queryStringParams.push(`start_date=${filterData.start_date}`);
    }
    if (filterData.end_date) {
      queryStringParams.push(`end_date=${filterData.end_date}`);
    }
    if (filterData.partner) {
      queryStringParams.push(`partner=${filterData.partner}`);
    }

    if (queryStringParams.length > 0) {
      apiUrl += `?${queryStringParams.join("&")}`;
    }

    axios({
      url: apiUrl,
    })
      .then((response) => {
        if (response.status) {
          const data = response.data;
          const labels = data.map((item) => item.date);
          const readData = data.map((item) => item.read);
          const deliveredData = data.map((item) => item.delivered);
          const failedData = data.map((item) => item.failed);
          const InitiatedData = data.map((item) => item.Initiated);

          const plotlyData = [
            {
              x: labels,
              y: readData,
              type: "scatter",
              mode: "lines",
              name: "Read",
              line: { color: "green", width: 3 }, // Increase line width
            },
            {
              x: labels,
              y: deliveredData,
              type: "scatter",
              mode: "lines",
              name: "Delivered",
              line: { color: "#cccccc", width: 3 }, // Increase line width
            },
            {
              x: labels,
              y: failedData,
              type: "scatter",
              mode: "lines",
              name: "Failed",
              line: { color: "red", width: 3 }, // Increase line width
            },
            {
              x: labels,
              y: InitiatedData,
              type: "scatter",
              mode: "lines",
              name: "Initiated",
              line: { color: "yellow", width: 3 }, // Increase line width
            },
          ];

          setLabels(labels);

          setPlotlyData(plotlyData);

          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  }, [axios, filterData]);

  const handlePointClick = (event) => {
    const notification = event.points[0].data.name;
    const date = new Date(event.points[0].x).getTime();
    let url = `leads/all?notifications=${notification}`;

    if (filterData.start_date) {
      url += `&start_date=${filterData.start_date}`;
    }
    if (filterData.end_date) {
      url += `&end_date=${filterData.end_date}`;
    }
    if (filterData.partner) {
      url += `&partner=${filterData.partner}`;
    }

    window.location.href = url;
  };

  return (
    <Fragment>
      {!loader ? (
        <Grid
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 20,
            textAlign: "center",
          }}
        >
          <Heading heading="Time Series Metrics" />
          <Plotly
            data={plotlyData}
            layout={{
              paper_bgcolor: "#f4f7fd",
              plot_bgcolor: "#f4f7fd",
              autosize: true,
              xaxis: {
                title: "Date",
                type: "category",
                categoryarray: [...new Set(labels)],
                tickmode: "array",
              },
              yaxis: {
                title: "Message Status",
              },
            }}
            useResizeHandler
            config={{
              displayModeBar: "hover",
            }}
            // onClick={handlePointClick}
          />
        </Grid>
      ) : (
        <ComponentLoader loading={loader} />
      )}
    </Fragment>
  );
}
