import queryString from "query-string";
import { useEffect, useState } from "react";

import Table from "./list components/Table";
import { PageHeader, Loader } from "../../components";
import { botAccessToken, botID } from "../../config/config";
import { useAxios, useComponent, useQueryParams } from "../../hooks";

export default function Transactions() {
  const paramsData = useQueryParams();

  const { axios, loader } = useComponent();

  const templateAxios = useAxios({
    baseURL: "adminV2",
    customAuthToken: botAccessToken,
  });
  const {
    partner = "",
    end_date = "",
    start_date = "",
    mobile = "",
    stage = "",
    name = "",
    transaction_id = "",
  } = queryString.parse(window.location.search);

  const [count, setCount] = useState();

  const [leads, setLeads] = useState([]);

  const [templates, setTemplates] = useState([]);

  const [filterData, setFilterData] = useState({
    filters: [
      {
        key: "",
        value: "",
        operator: "",
        relation: "",
      },
    ],
  });

  useEffect(() => {
    loader.start();

    let filterData = [];

    Object.keys(paramsData).map((items) => {
      if (items !== "start_date" && items !== "end_date") {
        filterData.push({
          key: items,
          dataType: "number",
          relation: "",
          value: paramsData[items],
          operator: "",
        });
      }
    });

    setFilterData({
      filters: filterData,
      ...(paramsData.start_date && {
        startTime: new Date(paramsData.start_date * 1000).toUTCString(),
      }),
      ...(paramsData.end_date && {
        endTime: new Date(paramsData.end_date * 1000).toUTCString(),
      }),
    });

    templateAxios({
      url: "/wa/template/" + botID + "?verified_status=3",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        loader.apiComplete();
        setTemplates(response.data.temp_obj);
      }
    });

    axios({
      url: `/leads?skip=0&limit=51&transaction_id=${transaction_id}&name=${name}&mobile=${mobile}${
        start_date && `&start_date=${start_date}`
      }${end_date && `&end_date=${end_date}`}${
        stage && `&stage=${stage}`
      }&partner=${partner}`,
    }).then((response) => {
      if (response.status) {
        setLeads(response.data.lead_list);
        setCount(response.data.total_count);
        loader.apiComplete();
      }
    });
  }, [
    name,
    stage,
    axios,
    loader,
    mobile,
    partner,
    end_date,
    start_date,
    templateAxios,
    transaction_id,
  ]);

  return (
    <Loader>
      <PageHeader pageName="Transactions" />

      <Table
        count={count}
        transaction_id={transaction_id}
        name={name}
        mobile={mobile}
        start_date={start_date}
        end_date={end_date}
        stage={stage}
        partner={partner}
        templates={templates}
        leads={leads}
        setLeads={setLeads}
        filterData={filterData}
        setFilterData={setFilterData}
      />
    </Loader>
  );
}
