import { makeStyles } from "@mui/styles";
import { Fragment, useEffect, useState } from "react";
import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";

import StageStepper from "./StageStepper";
import WhatsappPreview from "../../leads components/details components/template components/whatsapp interface/WhatsappPreview";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1515px", padding: "15px" },
}));

export default function ViewTemplateMetrics({
  viewMetrics,
  setViewMetrics,
  viewDataMetrics,
}) {
  const classes = useStyles();

  const [templateData, setTemplateData] = useState({
    templateFooter: {},
    templateBody: {},
    templateHeader: {},
    templateButtons: {},
  });

  useEffect(() => {
    setTemplateData({
      templateFooter: viewDataMetrics?.templateDetails?.template_footer || {},
      templateBody: viewDataMetrics?.templateDetails?.template_body || {},
      templateHeader: viewDataMetrics?.templateDetails?.template_header || {},
      templateButtons: viewDataMetrics?.templateDetails?.template_button || {},
    });
  }, [viewDataMetrics.templateDetails]);

  return (
    <Dialog
      open={viewMetrics}
      onClose={() => setViewMetrics(false)}
      classes={{ paper: classes.paper }}
    >
      <DialogContent>
        <Grid
          container
          item
          display={"flex"}
          justifyContent={"center"}
          marginBottom={2}
        >
          <Typography variant="h3">
            {viewDataMetrics.templateDetails?.name
              .replace(/_/g, " ")
              .toUpperCase()}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={{
            padding: "35px 15px",
            boxShadow: "0px 0px 2px 1px #0000008a",
            borderRadius: "10px",
            marginBottom: "15px",
          }}
        >
          <StageStepper data={viewDataMetrics?.templateMetrics?.stage} />
        </Grid>

        <Grid container display={"flex"} justifyContent={"end"}>
          <Grid item xs={4} sx={{ borderRadius: "10px" }}>
            <WhatsappPreview formikProps={templateData} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
