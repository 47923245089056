import { createGlobalStyle } from "styled-components/macro";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
    margin: 0;
    overflow: hidden;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
  .no-decoration-link {
    text-decoration: none;
  }


  .node__root > circle {
    fill: red;
  }
  
  .node__branch > circle {
    fill: yellow;
  }
  
  .node__leaf > circle {
    fill: green;
    /* Let's also make the radius of leaf nodes larger */
    
  }

`;

export default GlobalStyle;
