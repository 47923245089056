import AWS from "aws-sdk";

var useAws = true;

export default async function uploadToS3({ bucket, filePath, file, region }) {
  // Minio integration

  if (!file) {
    return { status: false };
  }
  AWS.config.update({
    region: bucket.region,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: bucket.identityPoolID,
    }),
  });
  let s3 = new AWS.S3({
    apiVersion: "2006-03-01",
    params: {
      Bucket: bucket.name,
    },
  });
  return new Promise((resolve) =>
    s3.upload(
      {
        Key: filePath,
        ACL: "public-read",
        Body: file,
        ContentType: file.type,
      },
      (err, data) => {
        resolve({ status: !err, data });
      }
    )
  );
  // } else {
  //   var minioClient = new Minio.Client({
  //     endPoint: "bucket.dolphinchat.ai",
  //     accessKey: "cogVXGnwbeaeheRs",
  //     secretKey: "9k7nfcZawYwrxSc510nBhvHtSifNeWUS",
  //     useSSL: true,
  //   });

  //   minioClient.presignedGetObject(
  //     "test",
  //     "text.txt",
  //     24 * 60 * 60,
  //     function (err, presignedUrl) {
  //       if (err) return console.log(err);
  //       console.log(presignedUrl);
  //     }
  //   );

  //   async function uploadtoMinio() {
  //     const response = await minioClient.putObject(
  //       bucket.name,
  //       file.name,
  //       // file name split
  //       filePath,
  //       function (err) {
  //         if (err) {
  //           return console.log(err);
  //         }
  //         return response;
  //       }
  //     );
  //   }

  //   const promise = uploadtoMinio();
  //   // promise.then();
  //   promise.catch((err) => {
  //     console.error(err);
  //   });
  // }

  // const minIoExecution = () => {
  //   console.log(minIo);
  // };

  // minIoExecution();

  // S3 part original starts below this
}
