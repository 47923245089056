import { Fragment } from "react";

import { DataTable } from "../../../../components";
import { convertDate } from "../../../../utils";

export default function ActionTable({ action }) {
  const options = {};

  const columns = [
    {
      name: "intent",
      label: "Intent name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "user_text",
      label: "Messages",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "created_at",
      label: "created At",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
  ];

  return (
    <Fragment>
      <DataTable columns={columns} options={options} data={action} />
    </Fragment>
  );
}
