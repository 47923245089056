export const templateValue = {
  templateID: "",
  userID: "",
  phoneNumber: "",
  numberList: [],
  templateFooter: {
    text: "",
    count_of_variable: 0,
  },
  templateBody: {
    text: "",
    count_of_variable: 0,
    example: [{ type: "variable", key: "" }],
  },
  templateHeader: {
    type: null,
    count_of_variable: 0,
    text: "",
    example: [],
  },
  templateButtons: {
    type: null,
    count_of_variable: 0,
    buttons: [],
  },
};

export function objectFromResponse(responseData) {
  return {
    templateFooter: responseData.template_footer || {},
    templateBody: responseData.template_body || {},
    templateHeader: responseData.template_header || {},
    templateButtons: responseData.template_button || {},
  };
}
