import React from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Grid, MenuItem } from "@mui/material";

import { useAxios } from "../../hooks";
import { TextField } from "../../components";
import { DateTimePicker } from "@mui/x-date-pickers";
import Label from "../../components/Label";

export default function DateFilterData({
  date,
  setDate,
  filterData,
  setFilterData,
  partner,
  setPartner,
}) {
  const axios = useAxios();

  function handlePartner(event) {
    setPartner(event.target.value);
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} direction="row" ml={5}>
        <Grid item xs={5}>
          <Label title={"Start Date"} name={"startTime"} />

          <DateTimePicker
            InputProps={{ readOnly: true }}
            name="startTime"
            inputProps={{
              "aria-label": "Without label",
              readOnly: true,
            }}
            value={date.startDate}
            onChange={(newValue) => {
              setDate((prev) => ({
                ...prev,
                startDate: newValue,
              }));
            }}
            renderInput={(params) => (
              <TextField
                readOnly={true}
                InputProps={{ readOnly: true }}
                name="startTime"
                {...params}
              />
            )}
          />

          <Label title={"End Date"} name={"endTime"} />

          <DateTimePicker
            InputProps={{ readOnly: true }}
            name="end_date"
            inputProps={{
              "aria-label": "Without label",
              readOnly: true,
            }}
            minDate={date.startDate}
            value={date.endDate}
            onChange={(newValue) => {
              setDate((prev) => {
                return {
                  ...prev,
                  endDate: newValue,
                };
              });
            }}
            renderInput={(params) => (
              <TextField
                readOnly={true}
                InputProps={{ readOnly: true }}
                name="end_date"
                {...params}
              />
            )}
          />
        </Grid>

        {filterData.partner !== undefined && (
          <Grid item xs={6}>
            <Label title={"Select Partner"} name={"Partner"} />
            <TextField
              fullWidth
              select
              label="Partner"
              value={partner}
              onChange={handlePartner}
            >
              <MenuItem value="pb">PB</MenuItem>
              <MenuItem value="tm">TM</MenuItem>
            </TextField>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
