import { Formik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";
import { useAxios, useAlert } from "../../../hooks";
import { signInMember } from "../../../redux/slices/memberSlice";
import { signInDetailsObject, validationSchema } from "./signInDetailsObject";

export default function Form() {
  const axios = useAxios({ disableAuthToken: true });
  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();

  return (
    <Formik
      enableReinitialize
      initialValues={signInDetailsObject}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/admin/login",
          method: "POST",
          data: {
            email: formData.email,
            password: formData.password,
          },
          disableRedirect: true,
        })
          .then(async (res) => {
            if (res.status) {
              await dispatch(
                signInMember({
                  authToken: res.data.auth_token,
                  rememberMe: formData.rememberMe,
                })
              );
              dispatch(alert({ message: "Welcome!", type: "success" }));
              navigate(
                search === "?redirect=back"
                  ? pages.insight.route
                  : pages.insight.route
              );
              setSubmitting(false);
            } else {
              setSubmitting(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <form autoComplete="off" onSubmit={formikProps.handleSubmit}>
          <FormDisplay formikProps={formikProps} />
        </form>
      )}
    </Formik>
  );
}
