import { Fragment } from "react";
import { Button } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import { convertDate } from "../../utils";
import { DataTable } from "../../components";
import { LoadingButton } from "@mui/lab";

export default function Table({ listData, setTemplateId, loading }) {
  const columns = [
    {
      name: "success",
      label: " ",
      options: {
        customBodyRender: (value, dataIndex) => {
          return (
            <Button style={{ color: "black" }}>{dataIndex.rowIndex + 1}</Button>
          );
        },
      },
    },

    {
      name: "success",
      label: "Success",
      options: {
        customBodyRender: (value) => (
          <Button style={{ color: "black", border: " 1px solid #36e136" }}>
            {value}
            <DoneIcon
              style={{
                color: "#36e136",
                marginLeft: "10px",
              }}
            ></DoneIcon>
          </Button>
        ),
      },
    },

    {
      name: "failed",
      label: "Failed",
      options: {
        customBodyRender: (value) => (
          <Button style={{ color: "black", border: " 1px solid #e15236" }}>
            {value}
            <CloseIcon
              style={{
                color: "#e15236",
                marginLeft: "10px",
              }}
            ></CloseIcon>
          </Button>
        ),
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Button
              style={{
                background:
                  value === "finished" ? "rgb(5 130 5)" : "rgb(250 203 21)",
                color: value === "finished" ? "white" : "rgb(38 30 0)",
                borderRadius: "7px",
                opacity: "0.75",
              }}
            >
              {value.toUpperCase()}
            </Button>
          ) : (
            "Not Available"
          );
        },
      },
    },

    {
      name: "created_at",
      label: "Created at",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },

    {
      name: "template_id",
      label: "View Template",
      options: {
        customBodyRender: (rowIndex, index) => (
          <LoadingButton
            onClick={() =>
              setTemplateId({
                templateID: rowIndex,
                index: index.rowIndex,
              })
            }
            loading={loading[index.rowIndex]}
          >
            View Template &#9755;
          </LoadingButton>
        ),
      },
    },
  ];

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  return (
    <Fragment>
      <DataTable columns={columns} options={options} data={listData} />
    </Fragment>
  );
}
