import ENV from "../config/config";

const URL_PREFIX = ENV === "main" ? "" : ENV + ".";

let url =
  ENV === "dev"
    ? "https://dev.api.dolphinchat.ai"
    : "https://api.dolphinchat.ai";

const BASE_URL = {
  admin: {
    url: "https://" + URL_PREFIX + "backend-dashboard.metassure.ai",
    version: "/v1",
  },

  rcApi: {
    url: "https://" + URL_PREFIX + "backend-rc-dashboard.metawing.ai",
    version: "/vahan/v1",
  },

  adminV2: {
    url: url,
    version: "/v2/admin",
  },

  messagingV2: {
    url: "https://" + URL_PREFIX + "api.dolphinchat.ai",
    version: "/v2/messaging",
  },

  analytic: {
    url:
      ENV === "main"
        ? "https://api-digipass.verismart.ai"
        : "https://" + URL_PREFIX + "backend-kyc-dashboard.metawing.ai",
    version: "/v1/analytics",
  },

  aadharUrl: {
    url: "https://govtapi.kycvideo.in/api/",
    version: "v1",
  },
};

export default BASE_URL;
