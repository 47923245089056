import { Avatar } from "@mui/material";
import { Adb, Person, SupportAgent } from "@mui/icons-material";
import styled from "styled-components";
import MessageBody from "./MessageBody";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

export default function Message({ message, userState }) {
  return (
    <Wrapper
      style={{
        flexDirection: message.actor === "user" ? "row" : "row-reverse",
      }}
    >
      <Avatar style={{ height: "30px", width: "30px" }}>
        {message.actor === "user" ? (
          <Person />
        ) : message.actor === "agent" ? (
          <SupportAgent />
        ) : (
          <Adb />
        )}
      </Avatar>

      <MessageBody message={message} userState={userState} />
    </Wrapper>
  );
}
