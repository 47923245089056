import * as Yup from "yup";

export let settingObject = {
  id: "",
  isActive: "",
  notificationDays: [56],
  isLogsAllowed: "44",
  buildQueueDaysBefore: "10",
  emailNotification: "75",
  whatsappNotification: "25",
  dailyNotificationLimit: "50",
  policyBazaarPercent: "60",
  turtleMintPercent: "5",
  isTesting: "",
  testingMobile: "",
  bannerImages: [],
};

export function objectFromResponse(data) {
  return {
    id: data._id,
    isActive: data.is_active,
    notificationDays: data.notification_days.map((items) => {
      return items.day;
    }),
    isLogsAllowed: data.is_logs_allowed,
    buildQueueDaysBefore: data.build_queue_days_before,
    emailNotification: data.email_notification,
    whatsappNotification: data.whatsapp_notification,
    dailyNotificationLimit: data.daily_notification_limit,
    policyBazaarPercent: data.policy_bazaar_percent,
    turtleMintPercent: data.turtle_mint_percent,
    isTesting: data.is_testing,
    testingMobile: data.testing_mobile,
    bannerImages: data.banner_images,
  };
}

export function objectFromFormData(formData) {}

export const validationSchema = Yup.object().shape({
  dailyNotificationLimit: Yup.string().required(
    "Daily Notification Limit is Required"
  ),
  buildQueueDaysBefore: Yup.string().required("Queue Days Before is Required"),
  notificationDays: Yup.array()
    .min(1)
    .required("Notification Days is Required"),
  policyBazaarPercent: Yup.number()
    .min(0)
    .max(100)
    .required("PolicyBazaar Percent is Required"),
  turtleMintPercent: Yup.number()
    .min(0)
    .max(100)
    .required("TurtleMint Percent is Required"),
});
