import React, { useState } from "react";
import { FilterList } from "@mui/icons-material";
import { IconButton, Tooltip, Badge, Grid } from "@mui/material";

import FilterDataDialog from "./FilterDataDialog";

export default function DateFilter({ filterData, setFilterData }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  function searchQueryCount() {
    let count = 0;

    for (let query in filterData) {
      if (!!filterData[query]) {
        count++;
      }
    }

    return count;
  }

  function handleClick() {
    setDialogOpen(true);
  }

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          // marginBottom: "20px",
        }}
      >
        <Tooltip title={"Filter Data"}>
          <IconButton onClick={handleClick}>
            <Badge
              variant="dot"
              badgeContent={searchQueryCount()}
              color="secondary"
            >
              <FilterList />
            </Badge>
          </IconButton>
        </Tooltip>

        <FilterDataDialog
          dialogOpen={dialogOpen}
          filterData={filterData}
          // setUserData={setUserData}
          setFilterData={setFilterData}
          //  stateList={stateList}
          //  districtList={districtList}
          //  departmentList={departmentList}
          setDialogOpen={setDialogOpen}
          //    visitors={visitors}
          //   setVisitors={setVisitors}
          //   setDistrictList={setDistrictList}
        />
      </Grid>
    </React.Fragment>
  );
}
