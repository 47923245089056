import * as React from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import { CheckCircle } from "@mui/icons-material";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const steps = [
  "Process Initiated",
  "Notification with Quotes",
  "Notification without Quotes",
  "Notifications Delivered",
  "Notification Read",
  "Interested in Renewal",
  "Went to Partner Page",
  "Quote Viewed",
  "Insurer Selected",
  "KYC Pending",
  "Payment Pending",
  "Payment Retry",
  "Inspection Pending",
  "Inspection In Review",
  "Inspection Rejected",
  "Booked",
];

const StageConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#096929",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#096929",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const StageStepRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#096929",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#096929",
    zIndex: 1,
    fontSize: 22,
  },
  "& .QontoStepIcon-circle": {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "#096929",
  },
}));

function StageIcon(props) {
  const { active, completed, className } = props;

  return (
    <StageStepRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckCircle className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </StageStepRoot>
  );
}

export default function StageStepper({ formikProps }) {
  let stage = formikProps.values.stage;

  if (stage === 14) {
    if (formikProps.values.remark === "failed") {
      stage = 14;
    } else {
      stage = 15;
    }
  }

  if (stage === 11) {
    if (formikProps.values.remark === "failed") {
      stage = 12;
    } else {
      stage = 11;
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={stage}
        connector={<StageConnector />}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StageIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
