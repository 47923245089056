import { useState } from "react";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAxios } from "../../../../hooks";
import { convertDate } from "../../../../utils";
import pages from "../../../../constants/pages";
import { DataTable } from "../../../../components";
import { botAccessToken, botID } from "../../../../config/config";
import WhatsappPreview from "../../../leads components/details components/template components/whatsapp interface/WhatsappPreview";

export default function NotificationsTable({ notificationsTable }) {
  const navigate = useNavigate();

  const templateAxios = useAxios({
    baseURL: "adminV2",
    customAuthToken: botAccessToken,
  });

  const [templatesData, setTemplatesData] = useState([]);

  const options = {};

  const columns = [
    {
      name: "created_at",
      label: "Notifications Start Date",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "delivery_status",
      label: "Delivery Status",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "template",
      label: "Template ID",
      options: {
        customBodyRender: (value) => {
          return value ? (
            <Button
              variant="outlined"
              onClick={() => handleViewTemplate(value)}
            >
              {value}
            </Button>
          ) : (
            "Not Available"
          );
        },
      },
    },
    {
      name: "before_expiry_days",
      label: "Notification Sent Before Expiry",
      options: {
        customBodyRender: (value) => {
          if (value === 0) {
            return (
              <span
                style={{
                  color: "red",
                  fontWeight: "bolder",
                  fontSize: "larger",
                }}
              >
                {value}
              </span>
            );
          }
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "transaction_id",
      label: " ",
      options: {
        customBodyRender: (value) => {
          return (
            <Button
              variant="outlined"
              onClick={() => navigate(pages.transactionDetails.route + value)}
            >
              View Details
            </Button>
          );
        },
      },
    },
  ];

  function handleViewTemplate(ID) {
    templateAxios({
      url: "/wa/template/" + botID + "?verified_status=3",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        let templateDetails = response.data.temp_obj.filter((items) => {
          return items._id === ID;
        });

        setTemplatesData({
          templateFooter: templateDetails[0]?.template_footer || {},
          templateBody: templateDetails[0]?.template_body || {},
          templateHeader: templateDetails[0]?.template_header || {},
          templateButtons: templateDetails[0]?.template_button || {},
        });
      }
    });
  }

  return (
    <Grid item container display={"flex"}>
      <Grid item xs={templatesData.length !== 0 ? 8 : 12}>
        <DataTable
          columns={columns}
          options={options}
          data={notificationsTable}
        />
      </Grid>

      {templatesData.length !== 0 && (
        <Grid item xs={4}>
          <WhatsappPreview formikProps={templatesData} />
        </Grid>
      )}
    </Grid>
  );
}
