import { Fragment, useEffect, useState } from "react";
import { Button } from "@mui/material";

import Table from "./Table";
import useAxios from "../../hooks/useAxios";
import { PageHeader } from "../../components";
import RequestUploadStatus from "./request upload status/RequestUploadStatus";
import { useComponent } from "../../hooks";

export default function UploadStatus() {
  const axios = useAxios();

  const [uploadStatusRequest, setuploadStatusRequest] = useState(false);

  const [listData, setListData] = useState([]);

  const { loader } = useComponent();

  useEffect(() => {
    axios({
      url: "/leads/pb-sheet/processes",
    }).then((response) => {
      if (response.status) {
        setListData(response.data);
      }
    });
  }, []);

  const [stages, SetStages] = useState();

  useEffect(() => {
    loader.start();

    let apiUrl = `/insights/conversion-rates`;

    axios({
      url: apiUrl,
    })
      .then((response) => {
        SetStages(response.data);

        loader.apiComplete();
      })
      .catch((err) => console.log(err));
  }, [axios, loader]);

  return (
    <Fragment>
      <PageHeader
        pageName={"Process Leads"}
        buttons={[
          <Button
            variant="contained"
            color="primary"
            onClick={() => setuploadStatusRequest(true)}
          >
            Process Leads
          </Button>,
        ]}
      />

      <RequestUploadStatus
        uploadStatusRequest={uploadStatusRequest}
        setuploadStatusRequest={setuploadStatusRequest}
        stages={stages}
      ></RequestUploadStatus>

      <Table listData={listData} />
    </Fragment>
  );
}
