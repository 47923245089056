import { convertDate } from "../../../utils";

export const leadsObject = {
  ID: "",
  rcNumber: "",
  mobileNumber: "",
  partner: "",
  optedOut: "",
  stage: 0,
  transactionId: "",
  remark: "",
  customerName: "",
  lastNotification: "",
  latestMinQuotes: "",
};

export function objectFromResponseData(responseData) {
  return {
    ID: responseData.customer_id,
    rcNumber: responseData.rc_number,
    mobileNumber: responseData.mobile,
    partner: responseData.partner,
    optedOut: responseData.opted_out,
    stage: responseData.stage,
    transactionId: responseData.transaction_id,
    remark: responseData.remark,
    customerName: responseData.customer_name,
    lastNotification: convertDate(responseData.last_notification),
    latestMinQuotes: responseData.latest_min_quotes,
  };
}
