import * as React from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import StepLabel from "@mui/material/StepLabel";

const steps = [
  "Process Initiated",
  "Notification with Quotes",
  "Notification without Quotes",
  "Notifications Delivered",
  "Notification Read",
  "Interested in Renewal",
  "Went to Partner Page",
  "Quote Viewed",
  "Insurer Selected",
  "KYC Pending",
  "Payment Pending",
  "Payment Retry",
  "Inspection Pending",
  "Inspection In Review",
  "Inspection Rejected",
  "Booked",
];

const StageStepRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ffffff",
  display: "flex",
  height: 22,
  alignItems: "center",
  "& .QontoStepIcon-completedIcon": {
    color: "#096929",
    zIndex: 1,
    fontSize: 22,
  },
  "& .QontoStepIcon-circle": {
    borderRadius: "50%",
    backgroundColor: "#24aef3",
    textAlign: "center",
    padding: "5px",
  },
}));

export default function StageStepper({ data }) {
  function StageIcon(props) {
    let stage = Object.keys(data).filter((items, index) => {
      return items == props + 1;
    })[0];

    const { active, completed, className } = props;

    return (
      <StageStepRoot ownerState={{ active, completed }}>
        <div className="QontoStepIcon-circle">{data[stage]}</div>
      </StageStepRoot>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={15} alternativeLabel>
        {steps.map((label, index) => {
          return (
            <Step key={index}>
              <StepLabel StepIconComponent={() => StageIcon(index)}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
