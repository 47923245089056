import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography, CircularProgress, Fade } from "@mui/material";

import "./index.css";

import { useAxios } from "../../../hooks";
import DateFilter from "../../Filter/DateFilter";
import ViewTemplateMetrics from "./ViewTemplateMetrics";
import { botAccessToken, botID } from "../../../config/config";

export default function TemplateMetrics() {
  const axios = useAxios();

  const templateAxios = useAxios({
    baseURL: "adminV2",
    customAuthToken: botAccessToken,
  });

  const [loading, setLoading] = useState(false);

  const [templateId, setTemplateId] = useState([]);

  const [templatesData, setTemplatesData] = useState([]);

  const [viewMetrics, setViewMetrics] = useState(false);

  const [viewDataMetrics, setViewDataMetrics] = useState({});

  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
    partner: "",
  });

  useEffect(() => {
    setLoading(true);

    templateAxios({
      url: "/wa/template/" + botID + "?verified_status=3",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setTemplatesData(response.data.temp_obj);
      }
    });

    axios({
      url:
        "/insights/templates" +
        (filterData.start_date && `?start_date=${filterData.start_date}`) +
        (filterData.end_date && `&end_date=${filterData.end_date}`) +
        (filterData.partner && `&partner=${filterData.partner}`),
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setTemplateId(response.data);
      } else {
        setTemplateId(response.message.errorMessage);
      }

      setLoading(false);
    });
  }, [axios, filterData]);

  function handleViewTemplateMetrics(templateMetrics, templateDetails) {
    setViewDataMetrics({
      templateMetrics: templateMetrics,
      templateDetails: templateDetails,
    });

    setViewMetrics(true);
  }

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        sx={{
          textTransform: "capitalize",
          padding: 10,
          margin: 10,
          display: "flex",
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
          backgroundColor: "#f4f7fd",
        }}
      >
        <DateFilter filterData={filterData} setFilterData={setFilterData} />

        <Grid container xs={12} justifyContent={"center"}>
          <Typography
            textAlign={"center"}
            sx={{
              fontWeight: 600,
              fontSize: "30px",
              paddingBottom: "15px",
            }}
          >
            Template Metrics
          </Typography>
        </Grid>

        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Fade
            in={loading}
            style={{
              transitionDelay: loading ? "800ms" : "0ms",
            }}
            unmountOnExit
          >
            <CircularProgress />
          </Fade>
        </Grid>

        {!loading && (
          <Fragment>
            <Grid
              container
              spacing={3}
              display={"flex"}
              justifyContent={"space-around"}
            >
              {Boolean(templateId?.map) ? (
                templateId?.map((items, index) => {
                  return (
                    <Grid
                      item
                      onClick={() =>
                        handleViewTemplateMetrics(
                          items,
                          templatesData.filter(
                            (itms) => itms._id === items.id
                          )[0]
                        )
                      }
                      sx={{
                        paddingRight: "15px !important",
                        paddingLeft: "15px !important",
                        paddingTop: "0px !important",
                        boxShadow: "0px 0px 2px",
                        borderRadius: " 5px",
                        margin: "5px",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography variant="h4" textAlign={"center"} pt={"20px"}>
                        {Boolean(
                          templatesData
                            .filter((itms) => itms._id === items.id)[0]
                            ?.name.replace(/_/g, " ")
                        )
                          ? templatesData
                              .filter((itms) => itms._id === items.id)[0]
                              ?.name.replace(/_/g, " ")
                          : items._id}
                      </Typography>

                      <Grid item>
                        {Object.keys(items.stage).map((key) => {
                          return (
                            <Typography
                              variant="h5"
                              sx={{
                                marginTop: "10px",
                                marginBottom: "5px",
                                textAlign: "center",
                              }}
                            >
                              STAGE &nbsp;&nbsp;{key} - {items.stage[key]}
                            </Typography>
                          );
                        })}
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <Typography variant="h3">
                  Error Message :- {templateId}
                </Typography>
              )}

              {templateId?.length === 0 && (
                <Typography variant="h3">No Data Found</Typography>
              )}
            </Grid>
          </Fragment>
        )}
      </Grid>

      <ViewTemplateMetrics
        viewMetrics={viewMetrics}
        setViewMetrics={setViewMetrics}
        viewDataMetrics={viewDataMetrics}
      />
    </Fragment>
  );
}
