import React from "react";

import TextField from "./../../../../../components/TextField";
import { Grid, FormControl, MenuItem, Typography, Button } from "@mui/material";

export default function AddButtonVariable({ formikProps, templateVariable }) {
  return (
    <React.Fragment>
      {formikProps.values?.templateButtons?.count_of_variable > 0 && (
        <Grid item xs={12}>
          <Typography variant="caption1">Button Variable</Typography>
        </Grid>
      )}

      {formikProps.values?.templateButtons?.count_of_variable > 0 &&
        Array(formikProps.values?.templateButtons?.buttons?.length)
          .fill()
          .map((_, index) => {
            return (
              formikProps.values?.templateButtons?.buttons[index]?.url_type ===
                "dynamic" && (
                <Grid container spacing={2} xs={12} pl={2}>
                  <Grid key={index} item mt={1} xs={3}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        label={`Buttons Variable Value ${index + 1}`}
                        disabled={Boolean(formikProps.values.ID)}
                        name={`templateButtons.example[${index}].key`}
                        fullWidth
                        formikProps={formikProps}
                        select
                      >
                        {templateVariable.map((item, itemIndex) => {
                          return (
                            <MenuItem
                              key={itemIndex}
                              disabled={
                                formikProps.values?.templateButtons?.example?.filter(
                                  (items, index) => {
                                    return items.key == item;
                                  }
                                ).length === 1
                              }
                              value={item}
                            >
                              {item.charAt(0).toUpperCase() +
                                item.slice(1).replace(/_/g, " ")}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </FormControl>
                  </Grid>

                  <Grid key={index} item mt={1} xs={3}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        label={`Select Buttons Variable Type${index + 1}`}
                        disabled={Boolean(formikProps.values.ID)}
                        name={`templateButtons.example[${index}].type`}
                        fullWidth
                        formikProps={formikProps}
                        select
                      >
                        <MenuItem value={"button"}>Button</MenuItem>

                        <MenuItem value={"variable"}>Variable</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              )
            );
          })}

      {formikProps.values.templateButtons?.count_of_variable > 0 && (
        <Grid item>
          <Button
            variant={"contained"}
            onClick={() =>
              formikProps.setFieldValue(`templateButtons.example`, [
                { type: "", key: "" },
              ])
            }
          >
            Reset
          </Button>
        </Grid>
      )}
    </React.Fragment>
  );
}
