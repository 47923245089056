import axios from "axios";
import { useDispatch } from "react-redux";
import { useRef, useEffect, useCallback } from "react";

import BASE_URL from "../constants/baseURL";
import { alert } from "../redux/slices/alertSlice";
import { deleteAccessToken } from "../redux/slices/memberSlice";

export default function useCustomAuthAxios({
  baseURL = "admin",
  disableAuthToken = false,
} = {}) {
  const dispatch = useDispatch();

  const axiosInstance = useRef(axios.create());

  useEffect(() => {
    axiosInstance.current = axios.create({
      baseURL: BASE_URL[baseURL].url + BASE_URL[baseURL].version,

      validateStatus: (status) => status < 500,
    });
  }, [disableAuthToken, baseURL]);

  return useCallback(
    ({
      url,
      method = "GET",
      data,
      params,
      authToken,
      disableRedirect = false,
    }) =>
      axiosInstance
        .current({
          url: url,
          method: method,
          data: data,
          params: params,
          headers: {
            ...(!disableAuthToken && {
              Authorization: "Bearer " + authToken || "",
            }),
            "content-type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 401) {
            dispatch(
              alert({
                message:
                  "Could not validate your credentials. Please log in again",
                type: "error",
              })
            );

            return dispatch(deleteAccessToken());
          }

          if (response.status > 201 || !response.data.status) {
            dispatch(
              alert({
                message: "Error loading data.",
                type: "error",
              })
            );
          }

          return response.data;
        }),
    [disableAuthToken, dispatch]
  );
}
