import { Fragment } from "react";
import { Formik } from "formik";

import FormDisplay from "./FormDisplay";

export default function Form({ authToken, editState, formData, templates }) {
  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {}}
    >
      {(formikProps) => (
        <Fragment>
          <FormDisplay
            authToken={authToken}
            templates={templates}
            editState={editState}
            formikProps={formikProps}
          />
        </Fragment>
      )}
    </Formik>
  );
}
