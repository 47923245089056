import React, { useState } from "react";
import {
  Grid,
  Button,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  Dialog as MUIDialog,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { Cancel } from "@mui/icons-material";

import DateFilterData from "./DateFilterData";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "800px" },
}));

export default function FilterDataDialog({
  dialogOpen,
  filterData,
  setDialogOpen,
  setFilterData,
}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [partner, setPartner] = useState(0);

  function clearFilter() {
    setFilterData({
      start_date: "",
      end_date: "",
      partner: "",
    });
    setDialogOpen(false);
  }

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <React.Fragment>
      <MUIDialog
        classes={{ paper: classes.paper }}
        open={dialogOpen}
        onClose={handleClose}
      >
        <Grid
          container
          spacing={0}
          style={{
            borderBottom: "1px solid #cbcbcb",
          }}
        >
          <Grid style={{ padding: "16px 24px" }} item xs={8}>
            <Typography variant="h5">Filter Data</Typography>
          </Grid>

          <Grid item xs={4}>
            <IconButton
              disableRipple
              style={{ float: "right", padding: "16px 24px" }}
              onClick={handleClose}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>

        <React.Fragment>
          <DialogContent
            style={{
              paddingTop: "10px",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  variant="caption1"
                  style={{ color: "rgb(128, 128, 128)" }}
                >
                  Apply filter to the usage data.
                </Typography>
              </Grid>

              <DateFilterData
                date={date}
                setDate={setDate}
                filterData={filterData}
                setFilterData={setFilterData}
                setDialogOpen={setDialogOpen}
                partner={partner}
                setPartner={setPartner}
              />
            </Grid>
          </DialogContent>

          <DialogActions style={{ padding: "12px 24px 24px" }}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs></Grid>

              <Grid item style={{ textAlign: "end" }}>
                <Button
                  onClick={clearFilter}
                  variant="outlined"
                  color="primary"
                >
                  Clear Filter
                </Button>
              </Grid>

              <Grid item style={{ textAlign: "end" }}>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={applyFilter}
                  loading={loading}
                >
                  Apply Filter
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogActions>
        </React.Fragment>
      </MUIDialog>
    </React.Fragment>
  );

  function applyFilter() {
    let startDate = new Date(date.startDate).getTime() / 1000;
    let endDate = new Date(date.endDate).getTime() / 1000;

    setFilterData((prev) => ({
      ...prev,
      start_date: startDate,
      end_date: endDate,
      partner: partner,
    }));

    setDialogOpen(false);
  }
}
