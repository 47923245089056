import axios from "axios";
import BASE_URL from "../../../constants/baseURL";

export default async function signIn({ authToken, rememberMe = false }) {
  if (rememberMe) {
    localStorage.setItem("authToken", authToken);
  } else {
    sessionStorage.setItem("authToken", authToken);
  }

  return {
    authToken: authToken,
  };
}
