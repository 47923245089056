import TextField from "./TextField";
import MuiAutoComplete from "@mui/material/Autocomplete";
import { get } from "lodash";

export default function Autocomplete({
  label,
  name,
  defaults,
  options,
  formikProps,
  ...props
}) {
  var initialValue = options.find(
    (option) =>
      option[defaults.primaryKey] === get(formikProps.values, name, "")
  );

  if (!initialValue) {
    initialValue = {
      [defaults.primaryKey]: "",
    };
  }

  return (
    <MuiAutoComplete
      freeSolo
      fullWidth
      forcePopupIcon={true}
      options={options}
      value={initialValue}
      getOptionLabel={(option) => get(option, defaults.displayLabel, "")}
      onChange={(_, value) => {
        formikProps.setValues((prevValue) => ({
          ...prevValue,
          [name]: value[defaults.primaryKey],
        }));
      }}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={label}
          name={name}
          error={Boolean(
            get(formikProps, "touched." + name) &&
              get(formikProps, "errors." + name)
          )}
          helperText={
            get(formikProps, "touched." + name) &&
            get(formikProps, "errors." + name)
          }
          onBlur={get(formikProps, "handleBlur")}
        />
      )}
    />
  );
}
