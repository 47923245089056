import { Grid } from "@mui/material";
import React, { Fragment } from "react";

import { PageHeader } from "../../components";
import UserActivity from "./User Activity/UserActivity";
import CustomerEngagement from "./customer engagement/index";
import TreeGraph from "./Sent Notifications Report/TreeGraph";
import OverallNotification from "./overall notification/index";
import TemplateMetrics from "./template metrics/TemplateMetrics";

export default function Insight() {
  // space
  return (
    <Fragment>
      <PageHeader pageName="Insights" />

      <Grid container spacing={2} sx={{ textTransform: "capitalize" }}>
        <OverallNotification />

        <CustomerEngagement />

        <TreeGraph />

        <UserActivity />

        <TemplateMetrics />
      </Grid>
    </Fragment>
  );
}
