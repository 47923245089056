import React from "react";
import { DataTable } from "../../../components";

export default function Table({ formikProps, rcDetails, downloadPdf }) {
  var formatted_data = [];

  if (!!rcDetails) {
    for (const key in rcDetails) {
      if (typeof rcDetails[key] === "string") {
        formatted_data.push({
          key: key.replace(/_/g, " "),
          value: rcDetails[key],
        });
      }
    }
  }

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
      rowsPerPage: 50,
      rowsPerPageOptions: [100],
      filter: false,
      viewColumns: false,
    },
    print: false,
  };

  const columns = [
    {
      name: "key",
      label: "Key",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "value",
      label: "Value",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
  ];

  return (
    <React.Fragment>
      <DataTable columns={columns} options={options} data={formatted_data} />
    </React.Fragment>
  );
}
