import { Grid } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

import Table from "./Table";
import { useAxios } from "../../hooks";
import { botAccessToken, botID } from "../../config/config";
import { PageHeader } from "../../components";
import WhatsappPreview from "../leads components/details components/template components/whatsapp interface/WhatsappPreview";

export default function Broadcast() {
  const axios = useAxios();

  const [listData, setListData] = useState([]);

  const [templateId, setTemplateId] = useState();

  const [loading, setLoading] = useState([]);

  const axiosBroadcast = useAxios({
    baseURL: "adminV2",
    customAuthToken: botAccessToken,
  });

  const [templateData, setTemplateData] = useState({
    templateFooter: {},
    templateBody: {},
    templateHeader: {},
    templateButtons: {},
  });

  useEffect(() => {
    axios({
      url: "/actionable/follow-up",
    }).then((response) => {
      if (response.status) {
        setListData(response.data.processes_list);
        setLoading(response.data.processes_list.map(() => false));
      }
    });
  }, []);

  useEffect(() => {
    if (templateId?.templateID) {
      setLoading((prev) => {
        return prev.map((items, index) => {
          return templateId.index === index ? true : items;
        });
      });

      axiosBroadcast({
        url: "/wa/template/" + botID + "?template_id=" + templateId.templateID,
      }).then((response) => {
        if (response.status) {
          setTemplateData({
            templateFooter: response.data.template_footer || {},
            templateBody: response.data.template_body || {},
            templateHeader: response.data.template_header || {},
            templateButtons: response.data.template_button || {},
          });

          setLoading((prev) => {
            return prev.map((items, index) => {
              return templateId.index === index ? false : items;
            });
          });
        }
      });
    }
  }, [templateId]);

  return (
    <Fragment>
      <PageHeader pageName={"Broadcast"} />

      <Grid item display={"flex"}>
        <div style={{ width: "67.5%" }}>
          <Table
            listData={listData}
            setTemplateId={setTemplateId}
            loading={loading}
            setLoading={setLoading}
          />
        </div>

        <Grid item>
          <WhatsappPreview formikProps={templateData}></WhatsappPreview>
        </Grid>
      </Grid>
    </Fragment>
  );
}
