const pages = {
  home: {
    ID: "home",
    route: "/",
  },
  insight: {
    ID: "insight",
    route: "/",
  },

  signIn: {
    ID: "signIn",
    route: "/sign-in",
  },

  transactionList: {
    ID: "transactionList",
    route: "/transaction/all",
  },

  transactionDetails: {
    ID: "transactionDetails",
    route: "/transaction-details/",
  },

  changePassword: {
    ID: "changePassword",
    route: "/change-password",
  },

  settings: {
    ID: "settings",
    route: "/settings/",
  },

  uploadStatus: {
    ID: "uploadStatus",
    route: "/upload-status/",
  },

  broadcast: {
    ID: "broadcast",
    route: "/broadcast/",
  },

  LeadsLists: {
    ID: "leads",
    route: "/leads/all",
  },

  LeadsDetails: {
    ID: "leadsDetails",
    route: "/leads-details/",
  },
};

export default pages;
