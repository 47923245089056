import { Fragment, useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { Sync } from "@mui/icons-material";

import Refresh from "../../../assets/refresh.gif";
import MessageSent from "./template components/MessageSent";
import StageStepper from "./stages components/StageStepper";
import { ComponentHeader, Paper, TextField } from "../../../components";
import { useAxios, useCustomAuthAxios } from "../../../hooks";
import { BsEye } from "react-icons/bs";
import RCDialog from "./rc details components/RCDialog";
import Table from "./Table";
import UpdateStage from "./stages components/UpdateStage";

export default function FormDisplay({ authToken, formikProps, templates }) {
  const axios = useAxios();

  const [popUp, setPopUp] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showRC, setShowRC] = useState(false);
  const [rcDetails, setRCDetails] = useState({});
  const [rcDetailsTable, setRCDetailsTable] = useState({});
  const [updateStageDialog, setUpdateStageDialog] = useState(false);

  const rcAxios = useCustomAuthAxios({
    baseURL: "rcApi",
  });

  useEffect(() => {
    if (formikProps.values.rcNumber && authToken) {
      rcAxios({
        url: "/vahan-get-details/" + formikProps.values?.rcNumber,
        authToken: authToken,
      }).then((response) => {
        if (response.status) {
          setRCDetails({
            vehicleNumber: response.data.rc_regn_no,
            make: response.data.rc_maker_desc,
            model: response.data.rc_maker_model,
            policyNo: response.data.rc_insurance_policy_no,
            insuranceUpto: response.data.rc_insurance_upto,
            insuranceCompany: response.data.rc_insurance_comp,
          });

          setRCDetailsTable(response.data);
        }
      });
    }
  }, [rcAxios, formikProps.values.rcNumber, authToken]);

  function refreshStage() {
    setRefresh(true);
    axios({ url: "/leads?transaction_id=" + formikProps.values.transactionId })
      .then((response) => {
        if (response.status) {
          formikProps.setValues((prev) => ({
            ...prev,
            stage: response.data.lead_list.stage,
            latestMinQuotes: response.data.lead_list.latest_min_quotes,
          }));
          setRefresh(false);
        }
      })
      .catch((err) => console.error(err));
  }

  return (
    <Fragment>
      <Paper>
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <Typography variant="h4">Transaction Stage </Typography>

            {refresh ? (
              <img style={{ height: "20px" }} src={Refresh} alt="refresh" />
            ) : (
              <IconButton onClick={refreshStage} style={{ padding: "0" }}>
                <Sync style={{ height: "20px" }} />
              </IconButton>
            )}
          </div>
        </Grid>

        <Grid item xs={12}>
          <StageStepper formikProps={formikProps} />
        </Grid>
      </Paper>

      <Paper>
        <Grid item xs={12}>
          <ComponentHeader
            title="Lead Details"
            subtitle={"Details of the lead."}
            buttons={[
              <Button
                variant="outlined"
                onClick={() => setUpdateStageDialog(true)}
              >
                Update Stage{" "}
              </Button>,
              <TemplateButton />,
            ]}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="ID"
            label="Customer ID"
            disabled
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="transactionId"
            label="Transaction ID"
            disabled
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="customerName"
            label="Customer Name"
            disabled
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="rcNumber"
            label="RC Number"
            disabled
            formikProps={formikProps}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <Tooltip title="View RC Details">
                    <IconButton color="primary" onClick={() => setShowRC(true)}>
                      <BsEye />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="mobileNumber"
            label="Mobile Number"
            disabled
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="partner"
            label="Partner"
            disabled
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="optedOut"
            label="Opted Out"
            disabled
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="remark"
            label="Remark"
            disabled
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="lastNotification"
            label="Last Notification"
            disabled
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            name="latestMinQuotes"
            label="Latest Quotation"
            disabled
            formikProps={formikProps}
            InputProps={{
              endAdornment: (
                <InputAdornment position={"end"}>
                  <Tooltip title="Fetch  Details">
                    <IconButton color="primary" onClick={() => refreshStage()}>
                      <Sync />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Paper>

      <Paper>
        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
          <Typography variant="h3">RC Details </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table rcDetails={rcDetailsTable} />
        </Grid>
      </Paper>

      <MessageSent
        templates={templates}
        popUp={popUp}
        setPopUp={setPopUp}
        formikProps={formikProps}
      />

      <RCDialog open={showRC} setOpen={setShowRC} rcDetails={rcDetails} />

      <UpdateStage
        parentFormikProps={formikProps}
        updateStageDialog={updateStageDialog}
        setUpdateStageDialog={setUpdateStageDialog}
        transactionID={formikProps.values.transactionId}
      />
    </Fragment>
  );

  function TemplateButton() {
    return (
      <Button variant="outlined" color="primary" onClick={() => setPopUp(true)}>
        Send Message
      </Button>
    );
  }
}
