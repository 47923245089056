import pages from "../../constants/pages";
import TransactionsDetails from "../../pages/leads components/LeadsDetails";
import Transactions from "../../pages/leads components/LeadsList";
import Insight from "../../pages/insights components/Insight";
import Settings from "../../pages/settings/Settings";
import Broadcast from "../../pages/broadcast/Broadcast";
import UploadStatus from "./../../pages/upload status/UploadStatus";
import ChangePassword from "../../pages/change password components/ChangePassword";
import Leads from "../../pages/leads/Leads";
import LeadsDetails from "./../../pages/leads/leads details/LeadsDetails";

const dashboardLayoutRoutes = {
  transactionListtRoute: {
    ID: pages.transactionList.ID,
    path: pages.transactionList.route,
    element: Transactions,
  },
  transactionDetailsRoute: {
    ID: pages.transactionDetails.ID,
    path: pages.transactionDetails.route + ":leadID",
    element: TransactionsDetails,
  },
  insightRoute: {
    ID: pages.insight.ID,
    path: pages.insight.route,
    element: Insight,
  },
  settingsRoute: {
    ID: pages.settings.ID,
    path: pages.settings.route,
    element: Settings,
  },
  uploadStatusRoute: {
    ID: pages.uploadStatus.ID,
    path: pages.uploadStatus.route,
    element: UploadStatus,
  },
  broadcastRoute: {
    ID: pages.broadcast.ID,
    path: pages.broadcast.route,
    element: Broadcast,
  },
  changePasswordRoute: {
    ID: pages.changePassword.ID,
    path: pages.changePassword.route,
    element: ChangePassword,
  },

  leadsListsRoute: {
    ID: pages.LeadsLists.ID,
    path: pages.LeadsLists.route,
    element: Leads,
  },

  leadsDetailsRoute: {
    ID: pages.LeadsDetails.ID,
    path: pages.LeadsDetails.route + ":customerID",
    element: LeadsDetails,
  },
};

export default dashboardLayoutRoutes;
