export default function customFieldDate(unixTimeStamp) {
  var timeStamp = new Date(unixTimeStamp * 1000);

  if (!(timeStamp > 0)) {
    return null;
  }

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = timeStamp.getFullYear();
  var month = months[timeStamp.getMonth()];

  var day =
    timeStamp.getDate() < 10 ? "0" + timeStamp.getDate() : timeStamp.getDate();

  var time = day + " " + month + " " + year;

  return time;
}
