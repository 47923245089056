import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Visibility } from "@mui/icons-material";
import { Checkbox, IconButton, Tooltip } from "@mui/material";

import { DataTable } from "../../components";
import { convertDate } from "../../utils";
import { useAxios } from "../../hooks";
import RequestDetails from "./view upload status request details/RequestDetails";

export default function Table({ listData }) {
  const axios = useAxios();

  const [requestDetails, setRequestDetails] = useState([]);

  const [requestDetailsDailog, setRequestDetailsDailog] = useState(false);

  function requestChatTranscriptsReport(index) {
    setRequestDetailsDailog(true);
    setRequestDetails(index);
  }

  const columns = [
    {
      name: "requested_by",
      label: "Process Id",
      options: {
        customBodyRender: (value, columnIndex) => {
          console.log(columnIndex, "columnIndex");
          return listData.length - columnIndex.rowIndex;
        },
      },
    },
    {
      name: "requested_by",
      label: "Created by",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "created_at",
      label: "Created at",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "updated_at",
      label: "Updated at",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },

    {
      name: "_id",
      label: "Action",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <div style={{ width: "35px" }}>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() =>
                    requestChatTranscriptsReport(
                      listData.filter((items) => items._id == value)
                    )
                  }
                >
                  <Tooltip title="View Request Detail">
                    <Visibility />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };
  return (
    <Fragment>
      <DataTable columns={columns} options={options} data={listData} />

      <RequestDetails
        requestDetails={requestDetails}
        requestDetailsDailog={requestDetailsDailog}
        setRequestDetailsDailog={setRequestDetailsDailog}
      ></RequestDetails>
    </Fragment>
  );
}
